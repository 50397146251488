import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import { debounce } from "lodash";
import { fetchData } from "../config/service";
import toast from "react-hot-toast";

const EditionSelect = ({
  value,
  onChange,
  onClear,
  className,
  style,
  ...props
}) => {
  const [editionsList, setEditionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [editionActivePage, setEditionActivePage] = useState(1);

  const limit = 10; // Adjust this value as needed
  //   const editionActivePage = 1; // You can implement pagination similarly to the destination logic if necessary

  useEffect(() => {
    const debouncedSearch = debounce((searchTerm) => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // Adjust debounce delay as needed

    debouncedSearch(searchTerm);

    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  useEffect(() => {
    fetchEditionOptions();
  }, [editionActivePage, debouncedSearchTerm]);

  const fetchEditionOptions = () => {
    let param = "";
    const limitNew = limit;
    const offsetNew = debouncedSearchTerm ? 0 : (editionActivePage - 1) * limit;

    param += `?limit=${limitNew}&offset=${offsetNew}&search=${encodeURIComponent(
      debouncedSearchTerm || ""
    )}`;

    setLoading(true);

    fetchData(`api/editions/editions-select/${param}`).then((res) => {
      setLoading(false);
      if (res.success) {
        const newData = res.data?.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });

        setEditionsList((prevData) => {
          // Combine previous records with new records
          const combinedData = [...(prevData || []), ...(newData || [])];

          // Remove duplicates based on value (ID)
          const uniqueData = Array.from(
            new Set(combinedData.map((item) => item.value))
          ).map((id) => combinedData.find((item) => item.value === id));

          return uniqueData;
        });
      } else {
        setEditionsList([]);
        toast.error(res.message);
      }
    });
  };

  return (
    <Select
      value={value}
      onChange={onChange}
      className={className}
      style={style}
      placeholder="Select Edition"
      loading={loading}
      showSearch
      filterOption={false}
      onSearch={(searchTerm) => setSearchTerm(searchTerm)}
      options={editionsList}
      allowClear
      size="large"
      onClear={() => {
        onClear();
        setEditionActivePage(1);
      }}
      onPopupScroll={(e) => {
        const bottom =
          Math.abs(
            e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
          ) < 1.5;

        if (bottom) {
          setLoading(true);
          setEditionActivePage((prevState) => prevState + 1);
        }
      }}
      notFoundContent={
        <>
          <div className="d-flex justify-content-center align-items-center">
            {loading ? <Spin size="small" /> : "No Data Found"}
          </div>
        </>
      }
      {...props}
    />
  );
};

export default EditionSelect;
