import { ForgotPassword, Login, Register } from "../pages";

export const Auth = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  { path: "/forgot-password", element: <ForgotPassword /> },
];
