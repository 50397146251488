import React, { useEffect, useState } from "react";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import { LIMITS } from "../../../../config";
import { Loader, Pagination } from "../../../../components";

const InvoiceData = ({ userId, edition_id }) => {
  const [limit, setLimit] = useState(LIMITS[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, [edition_id]);

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/payment/invoices-list-create/?user=${userId}&edition=${edition_id}&limit=${limit}&offset=${
        (activePage - 1) * limit
      }`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">Data for Invoices</h2>
        </div>

        <div className="card-toolbar"></div>
      </div>

      <div className="card-body p-9 pt-2">
        <div className="card-body py-2 pb-7">
          {loading ? (
            <div className="table-responsive">
              <Loader cols={7} width={250} />
            </div>
          ) : (
            <div>
              {data?.length > 0 ? (
                <>
                  <div className="table-responsive tbl-sales">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                      id
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-light-primary">
                          <th className="min-w-200px">Name on Invoice</th>
                          <th className="min-w-125px">Country</th>
                          <th className="min-w-125px">State</th>
                          <th className="min-w-125px">City</th>
                          <th className="min-w-125px">Address</th>
                          <th className="min-w-100px">PIN Code</th>
                          <th className="min-w-125px">GST Number</th>
                          <th className="min-w-125px">PAN Number</th>
                        </tr>
                      </thead>
                      <tbody className="fw-semibold fs-7">
                        {data.map((item, index) => (
                          <tr key={item.id}>
                            <td>{item.name_on_invoice}</td>
                            <td>{item.country_name}</td>
                            <td>{item.state_name}</td>
                            <td>{item.city_name}</td>
                            <td>{item.address}</td>
                            <td>{item.pin_code}</td>
                            <td>{item.gst_num}</td>
                            <td>{item.pan_num}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*end::Table*/}
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={setLimit}
                    limit={limit}
                    data={data}
                    activePage={activePage}
                    totalRecords={totalRecords}
                    onChange={(pageNumber) => {
                      setLoading(true);
                      setActivePage(pageNumber);
                    }}
                  />
                </>
              ) : (
                <div className="row g-5 mb-0 gx-md-10">
                  <div className="col-md-12 text-center">
                    <div className="mt-0">
                      <img
                        src="/media/no-data-found.png"
                        className="mw-25"
                        alt="No Data Found"
                      />
                    </div>
                    <h1 className="mt-4">No Data Found</h1>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceData;
