import React, { useState } from "react";
import { Drawer, Button, Select, DatePicker, Checkbox, Spin } from "antd"; // Removed Checkbox as it's not needed
import EditionSelect from "../../../../components/edition-select";

const BookingsFilterDrawer = ({
  show,
  filterData,
  setFilterDataValue,
  onHide,
  statusList,
  editionsList,
  editionId,
  projectList,
  destinationList,
  setFilterData,
  destinationLoading,
  setDestinationLoading,
  setDestinationActivePage,
  setDestinationSearhTerm,
}) => {
  const visaStatusList = [
    { label: "Approved", value: "approved" },
    { label: "Pending", value: "pending" },
    { label: "Rejected", value: "rejected" },
  ];

  // const editionsList = [
  //   { label: "Edition 1", value: "edition1" },
  //   { label: "Edition 2", value: "edition2" },
  // ];

  const paymentStatusList = [
    { label: "Paid", value: "paid" },
    { label: "Pending", value: "pending" },
    { label: "Refunded", value: "refunded" }, // Added Refunded
    { label: "Cancelled", value: "cancelled" }, // Added Cancelled
  ];

  const flightStatusList = [
    { label: "Added", value: "added" },
    { label: "Not Added", value: "not_added" },
  ];

  const curationStatusList = [
    { label: "Approved", value: "approved" },
    { label: "Under Review", value: "under_review" },
    { label: "Rejected", value: "rejected" },
  ];

  // const destinationList = [
  //   { label: "Destination 1", value: "dest1" },
  //   { label: "Destination 2", value: "dest2" },
  // ];

  // const projectList = [
  //   { label: "Project 1", value: "project1" },
  //   { label: "Project 2", value: "project2" },
  // ];

  return (
    <>
      <Drawer
        title="Bookings Filters"
        placement="left"
        onClose={onHide}
        visible={show}
        width={300}
      >
        {/* Edition Name - Multiple Select */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">Edition Name</label>
          {/* <EditionSelect
            value={filterData?.edition || editionId}
            onChange={(value) => setFilterDataValue("edition", value)}
            style={{ width: "100%" }}
            size="large"
            onClear={() => setFilterDataValue("edition", null)}
          /> */}

          <Select
            mode="multiple"
            value={filterData?.edition || editionId}
            onChange={(value) => setFilterDataValue("edition", value)}
            placeholder="Select Edition"
            style={{ width: "100%" }}
            options={editionsList}
            allowClear
            size="large"
          />
        </div>

        {/* Second Payment Status */}
        {/* <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">
            Second Payment Status
          </label>
          <Select
            value={filterData?.second_payment_status}
            onChange={(value) =>
              setFilterDataValue("second_payment_status", value)
            }
            placeholder="Select Payment Status"
            style={{ width: "100%" }}
            options={paymentStatusList}
            allowClear
            size="large"
          />
        </div> */}

        {/* Visa Status */}
        {/* <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">Visa Status</label>
          <Select
            value={filterData?.visa_status}
            onChange={(value) => setFilterDataValue("visa_status", value)}
            placeholder="Select Visa Status"
            style={{ width: "100%" }}
            options={visaStatusList}
            allowClear
            size="large"
          />
        </div> */}

        {/* Payment Status (with Refunded and Cancelled) */}
        {/* <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">Payment Status</label>
          <Select
            value={filterData?.payment_status}
            onChange={(value) => setFilterDataValue("payment_status", value)}
            placeholder="Select Payment Status"
            style={{ width: "100%" }}
            options={paymentStatusList}
            allowClear
            size="large"
          />
        </div> */}

        {/* Flight Status */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">Flight Status</label>
          <Select
            value={filterData?.flight_ticket_status}
            onChange={(value) =>
              setFilterDataValue("flight_ticket_status", value)
            }
            placeholder="Select Flight Status"
            style={{ width: "100%" }}
            options={flightStatusList}
            allowClear
            size="large"
          />
        </div>

        {/* Curation Status */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">Curation Status</label>
          <Select
            value={filterData?.curation_status}
            onChange={(value) => setFilterDataValue("curation_status", value)}
            placeholder="Select Curation Status"
            style={{ width: "100%" }}
            options={curationStatusList}
            allowClear
            size="large"
          />
        </div>

        {/* Destination - Multiple Select */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">Destination</label>
          <Select
            // getPopupContainer={(triggerNode) =>
            //   triggerNode.parentNode
            // }
            mode="multiple"
            showSearch
            onSearch={(value) => {
              setDestinationSearhTerm(value);
            }}
            optionFilterProp="label"
            value={filterData?.destination}
            onChange={(value) => {
              setFilterDataValue("destination", value);
            }}
            placeholder="Select Destination"
            className="fs-7 w-100"
            style={{ width: "100%" }}
            // dropdownStyle={{ zIndex: 1100, maxHeight: "200px" }}
            size="large"
            options={destinationList}
            loading={destinationLoading}
            onClear={() => {
              setFilterDataValue("destination", null);
              setDestinationActivePage(1);
            }}
            onPopupScroll={(e) => {
              const bottom =
                Math.abs(
                  e.target.scrollHeight -
                    (e.target.scrollTop + e.target.clientHeight)
                ) < 1.5;

              if (bottom) {
                setDestinationLoading(true);
                setDestinationActivePage((prevState) => prevState + 1);
              }
            }}
            notFoundContent={
              <>
                <div className="d-flex justify-content-center align-items-center">
                  {destinationLoading ? <Spin size="small" /> : "No Data Found"}
                </div>
              </>
            }
          ></Select>

          {/* <Select
            mode="multiple" // Multiple mode for Destination
            value={filterData?.destination}
            onChange={(value) => setFilterDataValue("destination", value)}
            placeholder="Select Destination"
            style={{ width: "100%" }}
            options={destinationList}
            allowClear
            size="large"
          /> */}
        </div>

        {/* Project - Multiple Select */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">Project</label>
          <Select
            mode="multiple" // Multiple mode for Project
            value={filterData?.project}
            onChange={(value) => setFilterDataValue("project", value)}
            placeholder="Select Project"
            style={{ width: "100%" }}
            options={projectList}
            allowClear
            size="large"
          />
        </div>

        {/* Joining Date - Range Picker */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">Joining Date</label>
          <DatePicker.RangePicker
            value={filterData?.joining_date}
            format="DD-MM-YYYY"
            onChange={(date, dateString) => {
              // console.log(date, dateString, "date");

              const utcDateString1 =
                date && date[0] ? date[0].format("YYYY-MM-DD") : null;

              const utcDateString2 =
                date && date[1] ? date[1].format("YYYY-MM-DD") : null;

              setFilterData((prevData) => ({
                ...prevData,
                start_date: utcDateString1,
                end_date: utcDateString2,
              }));
            }}
            style={{ width: "100%" }}
            size="large"
          />
        </div>
      </Drawer>
    </>
  );
};

export default BookingsFilterDrawer;
