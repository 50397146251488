import React, { useEffect, useState } from "react";
import { ManageHotels } from "./modals";
import { LIMITS, PROPERTIES } from "../../../config";
import { fetchData } from "../../../config/service";
import { Alert, Loader, Pagination } from "../../../components";
import toast from "react-hot-toast";
import { Project } from "./modals";
import { Image } from "antd";

export default function Projects() {
  const [projectModal, setProjectModal] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [genderOptions, setGenderOptions] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700); // 700ms debounce

    // Cleanup the timeout if searchTerm changes (before 500ms)
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getGenderOptions();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, debouncedSearchTerm]);

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    // Add search query if it exists
    if (debouncedSearchTerm) {
      param += `&search=${encodeURIComponent(debouncedSearchTerm || "")}`;
    }

    fetchData(`api/project/${param}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  const getGenderOptions = () => {
    fetchData(`api/accounts/user-choices/?type=gender_choices`).then((res) => {
      if (res.success) {
        const data = res["gender_choices"]?.map((e) => {
          return { label: e?.display_name, value: e?.value };
        });
        setGenderOptions(data);
      } else {
        setGenderOptions([]);
        toast.error(res.message);
      }
    });
  };

  const deleteItem = () => {
    fetchData(`api/project/${selected.id}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleModalHide = (shouldFetch = false) => {
    setSelected({});
    setProjectModal(false);

    if (shouldFetch) {
      setLoading(true);
      getData();
    }
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search Project here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="position-relative mr-4"></div>
              </div>
              <div className="card-toolbar">
                {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                {/* <div data-kt-daterangepicker="true"
                                                  data-kt-daterangepicker-opens="left"
                                                  class="btn btn-sm btn-white border border-gray-300 me-3 d-flex align-items-center px-4">
                                                  <div class="text-gray-600 fw-bold">
                                                      Loading date range...
                                                  </div>

                                                  <i class="ki-outline ki-calendar-8 fs-1 ms-2 me-0"></i>
                                              </div> */}
                {/*end::Daterangepicker*/}
                {/* <a class="btn btn-primary fs-7 fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_edition"><i class="ki-outline ki-plus-square fs-2"></i>Add Edition</a> */}
                <button
                  className="btn btn-primary fs-7 fw-bold"
                  onClick={() => {
                    setProjectModal(true);
                  }}
                >
                  <i className="ki-outline ki-plus-square fs-2" /> Add New
                  Project
                </button>
                {/* <a href="#" class="btn  fs-7 btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add New User</a> */}
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive tbl-sales">
                        {/*begin::Table*/}

                        <table
                          className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                          id
                        >
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              {/* <tr className="fw-bolder text-gray-900 bg-light-primary"> */}
                              <th class="min-w-75px">Sr. No.</th>
                              <th className="min-w-125px"> Project Name</th>
                              <th className="min-w-125px">Hero Image</th>
                              <th className="min-w-150px">Banner Image</th>
                              <th className="min-w-175px">Description</th>
                              <th className="min-w-125px">Action</th>
                            </tr>
                          </thead>
                          <tbody className=" fw-semibold fs-7">
                            {data?.map((project, i) => {
                              return (
                                <tr className="bg-light" key={project.id}>
                                  <td className="bdr-left-user text-center">
                                    {(activePage - 1) * limit + 1 + i}
                                  </td>
                                  <td>
                                    <span className="fs-6 fw-bold">
                                      {" "}
                                      {project.name || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <Image
                                      src={project.hero_image}
                                      alt={`${project.name} Banner`}
                                      // style={{
                                      //   width: "640px",
                                      //   height: "320px",
                                      //   objectFit: "cover",
                                      // }}
                                      width={90}
                                      height={90}
                                    />
                                  </td>
                                  <td>
                                    <Image
                                      src={project.banner_image}
                                      alt={`${project.name} Banner`}
                                      // style={{
                                      //   width: "1600px",
                                      //   height: "320px",
                                      //   objectFit: "cover",
                                      // }}

                                      width={90}
                                      height={90}
                                    />
                                  </td>
                                  <td>
                                    {" "}
                                    <span className="fs-6 fw-semibold">
                                      {" "}
                                      {project.description || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex flex-shrink-0 ">
                                      <span
                                        title="Edit"
                                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm me-3"
                                        onClick={() => {
                                          setProjectModal(true);

                                          setSelected(project);
                                        }}
                                      >
                                        <i className="ki-outline ki-pencil fs-2 text-dark" />
                                      </span>
                                      <span
                                        title="Delete"
                                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm"
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setSelected(project);
                                        }}
                                      >
                                        <i className="ki-outline ki-trash fs-2 text-danger" />
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/*end::Table*/}
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      <Project
        item={selected}
        show={projectModal}
        onHide={() => {
          setSelected({});
          setProjectModal(false);
        }}
        genderOptions={genderOptions}
        onSuccess={() => {
          setLoading(true);
          getData();
        }}
      />

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />
    </div>
  );
}
