import React, { useState } from "react";
import { Drawer, Button, Select, Checkbox, Spin } from "antd";

const FilterDrawer = ({
  show,
  filterData,
  setFilterDataValue,
  onHide,
  statusList,
  editionsList,
  projectList,
  destinationList,
  destinationLoading,
  setDestinationLoading,
  setDestinationActivePage,
  setDestinationSearhTerm,
}) => {
  //   const statusList = [
  //     { display_name: "Active", value: "active" },
  //     { display_name: "Inactive", value: "inactive" },
  //   ];

  //   const scoreOptions = [
  //     { name: "High", value: "high" },
  //     { name: "Low", value: "low" },
  //   ];

  //   const editionsList = [
  //     { label: "Edition 1", value: "edition1" },
  //     { label: "Edition 2", value: "edition2" },
  //   ];

  //   const statusList = [
  //     { display_name: "Active", value: "active" },
  //     { display_name: "Inactive", value: "inactive" },
  //   ];

  const scoreOptions = [
    { name: "High", value: "high" },
    { name: "Low", value: "low" },
  ];

  //   const editionsList = [
  //     { label: "Edition 1", value: "edition1" },
  //     { label: "Edition 2", value: "edition2" },
  //   ];

  const visaStatusList = [
    { label: "Approved", value: "approved" },
    { label: "Pending", value: "pending" },
    { label: "Rejected", value: "rejected" },
  ];

  const paymentStatusList = [
    { label: "Paid", value: "paid" },
    { label: "Pending", value: "pending" },
    { label: "Refunded", value: "refunded" },
    { label: "Cancelled", value: "cancelled" },
  ];

  const flightStatusList = [
    { label: "Added", value: "added" },
    { label: "Not Added", value: "not_added" },
  ];

  const curationStatusList = [
    { label: "Approved", value: "approved" },
    { label: "Under Review", value: "under_review" },
    { label: "Rejected", value: "rejected" },
  ];

  // const destinationList = [
  //   { label: "Destination 1", value: "dest1" },
  //   { label: "Destination 2", value: "dest2" },
  // ];

  // const projectList = [
  //   { label: "Project 1", value: "project1" },
  //   { label: "Project 2", value: "project2" },
  // ];

  return (
    <>
      <Drawer
        title="Users Filters"
        placement="left"
        onClose={onHide}
        visible={show}
        width={350}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className=" mb-5">
            <label className="form-label mb-2 fs-6 me-3">All Users:</label>
            <Checkbox
              checked={filterData?.all_users}
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;
                setFilterDataValue("all_users", value);
              }}
            />
          </div>

          <div className="mb-5">
            <label className="form-label mb-2 fs-6 me-3">Has Booked:</label>
            <Checkbox
              checked={filterData?.has_booking}
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;
                setFilterDataValue("has_booking", value);
              }}
            />
          </div>
        </div>
        {/* All Users Checkbox */}

        {/* Edition Name - Multiple Select */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2"> Edition Name</label>
          <Select
            mode="multiple"
            value={filterData?.edition}
            onChange={(value) => setFilterDataValue("edition", value)}
            placeholder="Select Edition"
            style={{ width: "100%" }}
            options={editionsList}
            allowClear
            size="large"
          />
        </div>

        {/* Second Payment Status */}
        {/* <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">
            Second Payment Status
          </label>
          <Select
            value={filterData?.second_payment_status}
            onChange={(value) =>
              setFilterDataValue("second_payment_status", value)
            }
            placeholder="Select Payment Status"
            style={{ width: "100%" }}
            options={paymentStatusList}
            allowClear
            size="large"
          />
        </div> */}

        {/* Visa Status */}
        {/* <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2"> Visa Status</label>
          <Select
            value={filterData?.visa_status}
            onChange={(value) => setFilterDataValue("visa_status", value)}
            placeholder="Select Visa Status"
            style={{ width: "100%" }}
            options={visaStatusList}
            allowClear
            size="large"
          />
        </div> */}

        {/* Payment Status (with Refunded and Cancelled) */}
        {/* <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2"> Payment Status</label>
          <Select
            value={filterData?.payment_status}
            onChange={(value) => setFilterDataValue("payment_status", value)}
            placeholder="Select Payment Status"
            style={{ width: "100%" }}
            options={paymentStatusList}
            allowClear
            size="large"
          />
        </div> */}

        {/* Flight Status */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2"> Flight Status</label>
          <Select
            value={filterData?.flight_ticket_status}
            onChange={(value) =>
              setFilterDataValue("flight_ticket_status", value)
            }
            placeholder="Select Flight Status"
            style={{ width: "100%" }}
            options={flightStatusList}
            allowClear
            size="large"
          />
        </div>

        {/* Curation Status */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2">Curation Status</label>
          <Select
            value={filterData?.curation_status}
            onChange={(value) => setFilterDataValue("curation_status", value)}
            placeholder="Select Curation Status"
            style={{ width: "100%" }}
            options={curationStatusList}
            allowClear
            size="large"
          />
        </div>

        {/* Destination - Multiple Select */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2"> Destination</label>

          <Select
            // getPopupContainer={(triggerNode) =>
            //   triggerNode.parentNode
            // }
            mode="multiple"
            showSearch
            onSearch={(value) => {
              setDestinationSearhTerm(value);
            }}
            optionFilterProp="label"
            // name="destination"
            value={filterData?.destination}
            onChange={(value) => {
              setFilterDataValue("destination", value);
            }}
            placeholder="Select Destination"
            className="fs-7 w-100"
            style={{ width: "100%" }}
            // dropdownStyle={{ zIndex: 1100, maxHeight: "200px" }}
            size="large"
            options={destinationList}
            loading={destinationLoading}
            onClear={() => {
              setFilterDataValue("destination", null);
              setDestinationActivePage(1);
            }}
            onPopupScroll={(e) => {
              const bottom =
                Math.abs(
                  e.target.scrollHeight -
                    (e.target.scrollTop + e.target.clientHeight)
                ) < 1.5;

              if (bottom) {
                setDestinationLoading(true);
                setDestinationActivePage((prevState) => prevState + 1);
              }
            }}
            notFoundContent={
              <>
                <div className="d-flex justify-content-center align-items-center">
                  {destinationLoading ? <Spin size="small" /> : "No Data Found"}
                </div>
              </>
            }
          ></Select>

          {/* <Select
            mode="multiple"
            value={filterData?.destination}
            onChange={(value) => setFilterDataValue("destination", value)}
            placeholder="Select Destination"
            style={{ width: "100%" }}
            options={destinationList}
            allowClear
            size="large"
          /> */}
        </div>

        {/* Project - Multiple Select */}
        <div className="mb-5">
          <label className="form-label mb-2 fs-6 me-2"> Project</label>
          <Select
            mode="multiple"
            value={filterData?.project}
            onChange={(value) => setFilterDataValue("project", value)}
            placeholder="Select Project"
            style={{ width: "100%" }}
            options={projectList}
            allowClear
            size="large"
          />
        </div>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
