import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { fetchData } from "../../../../../config/service";
import toast from "react-hot-toast";
import { Dropzone } from "../../../../../components";
import {
  DatePicker,
  Image,
  message,
  Select,
  Spin,
  TimePicker,
  Tooltip,
} from "antd";
import { validateFields } from "../../../../../components/validation";
import moment from "moment";
import dayjs from "dayjs";
import { objectValidation } from "../../../../../components/objectValidation";

export default function HighlightsDetails({
  show,
  onHide,
  data,
  highlightsModalDetails,
  setHighlightsModalDetails,
  hightLightModalImagePreview,
  setHighLightModalImagePreview,
  handleHighLightModalFileChange,
  handleHighlightFileRemove,
}) {
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    const { isValid: isValidHighlightModal, errors: highlightModalErrors } =
      objectValidation(highlightsModalDetails);

    if (!isValidHighlightModal) {
      console.error("Validation validation errors:", highlightModalErrors);
    } else {
      handleHide(true);
    }
  };

  const handleHide = (canClose = false) => {
    if (canClose) {
      onHide(); // Close modal if `canClose` is true
    } else {
      message.warning(
        "Please fill in all the required fields before closing the modal."
      );
    }
  };

  const handleDescriptionChange = (value, index) => {
    const updatedDetails = [...highlightsModalDetails];

    updatedDetails[index].highlight_description = value;
    setHighlightsModalDetails(updatedDetails);
  };

  // Function to add a new row
  const addLinkRow = () => {
    setHighlightsModalDetails([
      ...highlightsModalDetails,
      { url: "", highlight_description: "" },
    ]);
  };

  // Function to remove a row
  const removeLinkRow = (index) => {
    const updatedDetails = highlightsModalDetails.filter((_, i) => i !== index);
    setHighlightsModalDetails(updatedDetails);
  };

  // console.log(highlightsModalDetails, "highlightsModalDetails");

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">Highlights Details</h3>
      </Modal.Header>
      <div>
        <div
          className="modal-body pb-7 pt-5 px-lg-10"
          style={
            loading
              ? {
                  filter: "blur(5px)",
                  pointerEvents: "none",
                }
              : {}
          }
        >
          <div
            className="scroll-y pe-7 ps-3 h-400px"
            id="kt_modal_add_customer_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_customer_header"
            data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="row g-7">
              {highlightsModalDetails.map((detail, index) => (
                <div className="row g-7" key={index}>
                  {/* Image Upload Field */}
                  <div className="col-md-5 fv-row">
                    <label className="fs-6 fw-bold mb-2">Image</label>

                    {hightLightModalImagePreview[index] ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      >
                        <Image
                          src={hightLightModalImagePreview[index]}
                          alt="Quick Link Image"
                          style={{
                            width: "150px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            border: "2px solid #ccc",
                          }}
                        />
                        <i
                          title="Remove Image"
                          className="ki-outline ki-cross fs-2"
                          onClick={() => handleHighlightFileRemove(index)}
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-8px",
                            cursor: "pointer",
                            zIndex: 2,
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="form-control fs-7"
                        name="quickLinkImage"
                        onChange={(e) => {
                          handleHighLightModalFileChange(e, index);
                        }}
                      />
                    )}
                  </div>

                  {/* Description Field */}
                  <div className="col-md-5 fv-row">
                    <label className="fs-6 fw-bold mb-2">Highlight Text</label>
                    <textarea
                      className="form-control fs-7"
                      value={detail.highlight_description}
                      onChange={(e) =>
                        handleDescriptionChange(e.target.value, index)
                      }
                      placeholder="Enter HighLight Text"
                      rows={1}
                    />
                  </div>

                  {/* Add/Remove Button */}
                  <div className="col-md-2 fv-row d-flex align-items-center">
                    {index === 0 ? (
                      <Tooltip title="Add">
                        <div
                          className="ms-2 mt-8 cursor-pointer"
                          onClick={addLinkRow}
                        >
                          <i className="ki-outline fs-1 ki-plus-square text-success"></i>
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Remove">
                        <div
                          className="ms-2 mt-8 cursor-pointer"
                          onClick={() => removeLinkRow(index)}
                        >
                          <i className="ki-outline fs-1 ki-minus-square text-danger"></i>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light fs-7"
            onClick={() => onHide()}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary fs-7"
            onClick={() => onSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}
