import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";

import { Image, Select } from "antd";
import { fetchData } from "../../../../config/service";
import dayjs from "dayjs";
import { Loader } from "../../../../components";

export default function VisaInfoModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });
  const [visaInfoData, setVisaInfoData] = useState([]);

  useEffect(() => {
    console.log(data, "data");
    if (data?.is_visa) {
      getVisaInfoData();
    }
  }, [data]);

  const getVisaInfoData = () => {
    setLoading(true);
    fetchData(
      `customer/api/visa/visa-information-details/${data?.edition}/?user=${data?.user?.uuid}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        console.log(res, "visaInfoData");
        setVisaInfoData(res);
      } else {
        toast.error(res.message);
      }
    });
  };

  const formatDate = (dateString) => {
    return dateString ? dayjs.utc(dateString).format("DD-MM-YYYY h:mm a") : "-";
  };

  // Helper function to format dates

  // Render info row with icon
  const InfoRow = ({ icon, label, value, badge = false }) => (
    <div className="d-flex align-items-center mb-3">
      <div className="d-flex align-items-center me-3">
        <i className={`ki-outline ${icon} text-primary fs-2 me-2`}></i>
        <span className="fw-semibold text-muted">{label}:</span>
      </div>
      {badge ? (
        <span className="badge badge-light-primary ms-auto">{value}</span>
      ) : (
        <span className="fw-bold ms-auto">{value}</span>
      )}
    </div>
  );

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Visa Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader cols={2} />
        ) : (
          <>
            {visaInfoData && data?.is_visa ? (
              <>
                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="card  h-100">
                      <div className="card-header py-3">
                        <div className="card-title">
                          <i class="ki-outline ki-document fs-2 me-2 text-primary"></i>

                          <h3 className="card-label text-gray-800">
                            Visa Details
                          </h3>
                        </div>
                      </div>
                      <div className="card-body pt-2">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="d-flex align-items-center">
                            <i className="ki-outline ki-geolocation fs-3 me-2 text-info"></i>
                            <span className="fw-semibold text-muted">
                              Destination
                            </span>
                          </div>
                          <span className="fw-bold">
                            {visaInfoData?.destination}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="d-flex align-items-center">
                            <i className="ki-outline ki-status fs-3 me-2 text-danger"></i>
                            <span className="fw-semibold text-muted">
                              Visa Status
                            </span>
                          </div>
                          <span className="badge badge-light-primary">
                            {visaInfoData?.visa_status_label}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <i className="ki-outline ki-document fs-3 me-2 text-warning"></i>
                            <span className="fw-semibold text-muted">
                              Visa Service
                            </span>
                          </div>
                          <span className="fw-bold">
                            {visaInfoData?.visa_service_label}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card card h-100">
                      <div className="card-header border-bottom  py-3">
                        <div className="card-title">
                          <i className="ki-outline ki-user fs-2 me-2 text-primary"></i>
                          <h3 className="card-label text-gray-800">
                            Personal Information
                          </h3>
                        </div>
                      </div>
                      <div className="card-body pt-2">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="d-flex align-items-center">
                            <i className="ki-outline ki-heart fs-3 me-2 text-danger"></i>
                            <span className="fw-semibold text-muted">
                              Marital Status
                            </span>
                          </div>
                          <span className="fw-bold">
                            {visaInfoData?.marital_status_label}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="d-flex align-items-center">
                            <i className="ki-outline ki-briefcase fs-3 me-2 text-success"></i>
                            <span className="fw-semibold text-muted">
                              Employment
                            </span>
                          </div>
                          <span className="fw-bold">
                            {visaInfoData?.employment_status_label}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <i className="ki-outline ki-dollar fs-3 me-2 text-warning"></i>
                            <span className="fw-semibold text-muted">
                              Annual Income
                            </span>
                          </div>
                          <span className="fw-bold">
                            {visaInfoData?.annual_income_label} ₹
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="card ">
                      <div className="card-header py-3">
                        <div className="card-title">
                          <i className="ki-outline ki-calendar-tick fs-2 me-2 text-primary"></i>
                          <h3 className="card-label text-gray-800">
                            Visa Timeline
                          </h3>
                        </div>
                      </div>
                      <div className="card-body pt-2">
                        <div className="row">
                          <div className="col-md-4 mb-2">
                            <div className="d-flex align-items-center">
                              <i className="ki-outline ki-arrow-right fs-3 me-2 text-primary"></i>
                              <span className="fw-semibold text-muted me-2">
                                Appointment:
                              </span>
                              <span className="fw-bold">
                                {new Date(
                                  visaInfoData?.preferred_appointment_date
                                ).toLocaleDateString()}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4 mb-2">
                            <div className="d-flex align-items-center">
                              <i className="ki-outline ki-arrow-right fs-3 me-2 text-success"></i>
                              <span className="fw-semibold text-muted me-2">
                                Start Date:
                              </span>
                              <span className="fw-bold">
                                {new Date(
                                  visaInfoData?.visa_start
                                ).toLocaleDateString()}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4 mb-2">
                            <div className="d-flex align-items-center">
                              <i className="ki-outline ki-arrow-right fs-3 me-2 text-danger"></i>
                              <span className="fw-semibold text-muted me-2">
                                End Date:
                              </span>
                              <span className="fw-bold">
                                {new Date(
                                  visaInfoData?.visa_end
                                ).toLocaleDateString()}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row g-5 mb-0 gx-md-10">
                  <div className="col-md-12 text-center">
                    <div className="mt-0">
                      <img
                        src="/media/no-data-found.png"
                        className="mw-25"
                        alt="No Data Found"
                      />
                    </div>
                    <h1 className="mt-4">No Data Found</h1>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
