import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { routesList } from "../config";

const MenuItem = ({ iconClass, title, children, includes }) => {
  const location = useLocation();

  const [isHovered, setIsHovered] = useState(false);
  const [transform, setTransform] = useState({ x: 0, y: 0 });
  const menuItemRef = useRef(null);

  useEffect(() => {
    handleMouseLeave();
  }, [location]);

  const handleMouseEnter = () => {
    const menuItem = menuItemRef.current;
    if (menuItem) {
      const { left, bottom } = menuItem.getBoundingClientRect();
      setTransform({ x: left, y: bottom });
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2
        ${location.pathname.includes(includes) && "here"}
        ${isHovered ? "show menu-dropdown" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={menuItemRef}
    >
      <span className="menu-link">
        <span className="menu-icon">
          <i className={iconClass} />
        </span>
        <span className="menu-title">{title}</span>
        <span className="menu-arrow d-lg-none" />
      </span>
      <div
        className={`menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-250px ${
          isHovered ? "show" : ""
        }`}
        style={{
          zIndex: 107,
          position: "fixed",
          inset: "0px auto auto 0px",
          margin: "0px",
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0px)`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default MenuItem;
