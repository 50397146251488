import React from "react";
const tab4Data = [
  {
    user: {
      name: "Khushbu Khetan",
      avatar: "/media/img.jpg",
    },
    paidOn: "-",
    edition: "-",
    totalPayments: "-",
    dueDate: "-",
    amountPaid: "-",
    comments: "-",
  },
  // Add more row objects as needed
];

const SecondPayments = () => {
  return (
    <div>
      <div className="table-responsive tbl-sales">
        {/*begin::Table*/}
        <table
          className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d"
          id
        >
          <thead>
            <tr className="fw-bolder text-gray-900 bg-light">
              <th className="min-w-100px">User</th>
              <th className="min-w-90px">Paid On</th>
              <th className="min-w-90px">Edition</th>
              <th className="min-w-150px">Total Payments</th>
              <th className="min-w-100px">Due Date</th>
              <th className="min-w-100px">Amount Paid</th>
              <th className="min-w-100px">Comments</th>
            </tr>
          </thead>
          <tbody className="fw-semibold fs-7">
            {tab4Data.map((row, index) => (
              <tr key={index}>
                <td className="bdr-left-user">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-30px overflow-hidden me-3">
                      <img src={row.user.avatar} alt={row.user.name} />
                    </div>
                    <div className="d-flex flex-column text-start">
                      <a
                        href="#"
                        className="text-primary mb-1 fw-bolder fs-6 min-w-150px"
                      >
                        {row.user.name}
                      </a>
                    </div>
                  </div>
                </td>
                <td>{row.paidOn}</td>
                <td>{row.edition}</td>
                <td>{row.totalPayments}</td>
                <td>{row.dueDate}</td>
                <td>{row.amountPaid}</td>
                <td>{row.comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SecondPayments;
