import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";

import { validateFields } from "../../../../components/validation";
import { fetchData } from "../../../../config/service";

export default function AirportModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });
  //   const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({});
  const [airportData, setAirportData] = useState({
    name: "",
    code: "",
    city: "",
    country: "",
    latitude: "",
    longitude: "",
    numberOfTerminals: "",
    passengerTraffic: "",
    timezone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAirportData({ ...airportData, [name]: value });
  };

  const handleSave = () => {
    console.log("Saved Data:", airportData);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        // Call the API after the image preview is set
        toast.loading("Uploading Image...");
        uploadImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (imageFile) => {
    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      ...(imageFile && isURL(imageFile) ? {} : { photo: imageFile || "" }),
    };

    // console.log(body, "body ");

    fetchData(`api/accounts/${userId}/`, "put", body, "", true).then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        toast.success("Image Upload Successful");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleRemoveImage = () => {
    setFormData((prevState) => ({
      ...prevState,
      image: null,
    }));
    setImagePreview(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (data) {
      // console.log(data, "user_data");
      setUserId(data?.uuid);
      setFormData({
        image: data.photo || null,
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        phone: data.phone || "",
        country_code: data.user_profile?.country || "",
        instagram: data.user_profile?.instagram_url || "",
        linkedin: data.user_profile?.linkedin_url || "",
        designation: data.user_profile?.designation || "",
        work: data.user_work[0]?.name || "",
        website: data.user_work[0]?.work_url || "",
        bio: data.user_profile?.bio || "",
      });
      setImagePreview(data?.photo);
    }
  }, [data]);

  const validationRules = {
    // image: { required: true, type: "image" },
    first_name: { required: true, maxLength: 50 },
    last_name: { required: true, maxLength: 50 },
    phone: { required: true, maxLength: 20 },
    // country_code: { required: true, maxLength: 5 },
    instagram: { required: true, type: "url", maxLength: 100 },
    linkedin: { required: true, type: "url", maxLength: 100 },
    designation: { required: true, maxLength: 100 },
    // work: { required: true, maxLength: 100 },
    // website: { type: "url", maxLength: 100 },
    bio: { required: true, maxLength: 500 },
  };

  const onSubmitUser = async () => {
    const { isValid } = validateFields(formData, validationRules);

    if (!isValid) {
      return;
    } else {
      toast.loading("Updating...");
    }

    setSending(true);

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      //   phone: formData.phone,
      //   country_code: formData.country_code,
      //   ...(formData.designation
      //     ? {
      //         user_work_details: [
      //           {
      //             designation: formData.designation,
      //           },
      //         ],
      //       }
      //     : {}),
      ...(formData?.instagram || formData?.linkedin || formData?.bio
        ? {
            user_profile: {
              instagram_url: formData?.instagram,
              linkedin_url: formData?.linkedin,
              bio: formData?.bio,
              designation: formData?.designation,
            },
          }
        : {}),
      // ...(formData?.image && isURL(formData?.image)
      //   ? {}
      //   : { photo: formData?.image || "" }),
    };

    // console.log(body, "body ");

    fetchData(`api/accounts/${userId}/`, "put", body).then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setCustomerDataValue = (key, value) => {
    const common_data = {
      ...customerData,
      [key]: value,
    };
    setCustomerData(common_data);
  };

  const scoreOptions = [
    { value: 0, name: "0" },
    { value: 1, name: "1" },
    { value: 2, name: "2" },
    { value: 3, name: "3" },
    { value: 3.5, name: "3.5" },
    { value: 4, name: "4" },
    { value: 4.5, name: "4.5" },
    { value: 5, name: "5" },
  ];

  return (
    <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Edit Customer <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body pb-7 pt-5 px-lg-10">
          <div
            className="scroll-y pe-7 ps-3"
            id="kt_modal_add_customer_scroll"
            style={{
              height: "400px",
            }}
          >
            <div className="row g-5 mb-5 align-items-center">
              <div className="col-md-12 fv-row mt-10">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={airportData.name}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

              <div className="col-md-12 fv-row mt-10">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Code
                </label>
                <input
                  type="text"
                  name="code"
                  value={airportData.code}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-12 fv-row mt-10">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  value={airportData.city}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-12 fv-row mt-10">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={airportData.name}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-12 fv-row mt-10">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={airportData.name}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-12 fv-row mt-10">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={airportData.name}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-12 fv-row mt-10">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={airportData.name}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-12 fv-row mt-10">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={airportData.name}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

              <div className="col-md-12 fv-row mt-10">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={airportData.name}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={airportData.city}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>Country</label>
                <input
                  type="text"
                  name="country"
                  value={airportData.country}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Latitude</label>
                <input
                  type="text"
                  name="latitude"
                  value={airportData.latitude}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Longitude</label>
                <input
                  type="text"
                  name="longitude"
                  value={airportData.longitude}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>Number of Terminals</label>
                <input
                  type="text"
                  name="numberOfTerminals"
                  value={airportData.numberOfTerminals}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Passenger Traffic</label>
                <input
                  type="text"
                  name="passengerTraffic"
                  value={airportData.passengerTraffic}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Timezone</label>
                <input
                  type="text"
                  name="timezone"
                  value={airportData.timezone}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
