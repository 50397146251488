import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { DatePicker, Select } from "antd";
import { fetchData } from "../../../config/service";
import dayjs from "dayjs";
import { validateFields } from "../../../components/validation";

export default function TribeModal({ show, onHide, data, cities, onSuccess }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");

  // Initialize empty state
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    start_date: "",
    address: "",
    image: "",
    city: null,
    about: "",
    term_conditions: "",
    tags: null,
  });
  const [images, setImages] = useState({
    bannerImage: null,
  });
  const [imagePreviews, setImagePreviews] = useState({
    bannerImagePreview: null,
  });

  // Populate formData if data.id is present
  useEffect(() => {
    if (data.id) {
      setFormData({
        name: data.name || "",
        start_date: data.start_date || "",
        address: data.address || "",
        image: data.image || "",
        city: data.city || "",
        about: data.about || "",
        term_conditions: data.term_conditions || "",
        tags: data.tags || null, // Join tags as comma-separated string
      });
      setImages({ bannerImage: data?.image });
      setImagePreviews({ bannerImagePreview: data?.image });
    } else {
      setFormData({
        name: "",
        start_date: "",
        address: "",
        image: "",
        city: null,
        about: "",
        term_conditions: "",
        tags: null,
      });
      setImages({ bannerImage: null });
      setImagePreviews({ bannerImagePreview: null });
    }
  }, [data]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validationRules = {
    name: {
      required: true,
    },
    start_date: {
      required: true,
    },
    address: {
      required: false,
    },
    city: {
      required: true,
    },
    about: {
      required: false,
    },
    term_conditions: {
      required: false,
    },
    tags: {
      required: true,
    },
  };

  const onSubmitEvent = async () => {
    const { isValid } = validateFields(formData, validationRules);

    if (!isValid) {
      return;
    } else {
      if (!images?.bannerImage) {
        toast.error("Please select an Banner image");
        return;
      } else {
        if (data?.id) {
          toast.loading("Updating Event...");
        } else {
          toast.loading("Adding Event...");
        }
      }
    }
    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      name: formData.name,
      start_date: formData.start_date,
      address: formData.address,
      city: formData?.city || " ",
      about: formData.about,
      term_conditions: formData.term_conditions,
      tags: formData.tags,
      ...(images?.bannerImage && isURL(images?.bannerImage)
        ? {}
        : { image: images?.bannerImage || "" }),
    };

    setSending(true);

    // console.log(body, "body");
    let api = data?.id ? `api/tribe/${data?.id}/` : "api/tribe/";
    let method = data?.id ? "put" : "post";

    fetchData(api, method, body, "", true).then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        toast.success("Success");
        onHide();
        onSuccess();
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleImagesChange = (e, imageType) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          // If dimensions are correct or imageType is not "bannerImage", update the state
          setImages((prevImages) => ({
            ...prevImages,
            [imageType]: file,
          }));

          setImagePreviews((prevImagePreviews) => ({
            ...prevImagePreviews,
            [`${imageType}Preview`]: reader.result,
          }));
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImages = (imageType) => {
    setImages((prevImages) => ({
      ...prevImages,
      [imageType]: null,
    }));

    setImagePreviews((prevImagePreviews) => ({
      ...prevImagePreviews,
      [`${imageType}Preview`]: null,
    }));
  };

  const options = [
    { label: "Invite Only", value: 1 },
    { label: "Buy Your Own Ticket", value: 2 },
  ];

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Add Event <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {/* Name and Start Date */}
          <div className="row mb-3">
            <div className="col">
              <label htmlFor className="fs-6 fw-bold mb-2 required">
                Banner Image
              </label>

              {imagePreviews?.bannerImagePreview ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={imagePreviews?.bannerImagePreview}
                    alt="Profile Preview"
                    style={{
                      width: "150px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "2px solid #ccc",
                    }}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2"
                    onClick={() => handleRemoveImages("bannerImage")}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="form-control fs-7"
                  onChange={(e) => handleImagesChange(e, "bannerImage")}
                />
              )}
            </div>

            <div className="col">
              <label className="fs-6 fw-bold mb-2 required">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          {/* Address */}
          <div className="row mb-3">
            <div className="col">
              <label className="fs-6 fw-bold mb-2 required">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          {/* Image URL and City */}
          <div className="row mb-3">
            <div className="col">
              <label className="fs-6 fw-bold mb-2 required">Start Date</label>
              <DatePicker
                format="DD-MM-YYYY"
                value={
                  formData.start_date
                    ? dayjs(formData.start_date) // Parse the existing start date using Day.js
                    : null
                }
                onChange={(date, dateString) => {
                  const utcDateString = date
                    ? date.format("YYYY-MM-DD HH:mm")
                    : null;
                  setFormData({
                    ...formData,
                    start_date: utcDateString ? utcDateString : null,
                  });
                }}
                className="fs-2 w-100"
                placeholder="Pick a Start Date"
                size="large"
              />
            </div>
            <div className="col">
              <label className="fs-6 fw-bold mb-2 required">City</label>
              <Select
                showSearch
                optionFilterProp="label"
                name="city"
                value={formData.city}
                onChange={(value) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    city: value,
                  }));
                }}
                placeholder="Select City"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={cities}
              ></Select>
            </div>
          </div>

          {/* About */}
          <div className="row mb-3">
            <div className="col">
              <label className="fs-6 fw-bold mb-2 required">About</label>
              <textarea
                name="about"
                value={formData.about}
                onChange={handleChange}
                className="form-control"
                rows="3"
              ></textarea>
            </div>
          </div>
          {/* Terms and Conditions */}
          <div className="row mb-3">
            <div className="col">
              <label className="fs-6 fw-bold mb-2 required">
                Terms & Conditions
              </label>
              <textarea
                name="term_conditions"
                value={formData.term_conditions}
                onChange={handleChange}
                className="form-control"
                rows="3"
              ></textarea>
            </div>
          </div>
          {/* Tags */}
          <div className="row mb-3">
            <div className="col">
              <label className="fs-6 fw-bold mb-2 required">Tags</label>
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="label"
                name="tags"
                value={formData.tags}
                onChange={(value) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    tags: value,
                  }));
                }}
                placeholder="Select Tags"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={options}
              ></Select>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitEvent();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
