import React, { useEffect, useState } from "react";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Button, Popover, Select, Tooltip, Typography } from "antd";
import { LIMITS, routesList } from "../../../config";
import { Loader } from "../../../components";
import CallsModal from "./modals/calls";
import NotesDrawer from "./drawer/notes";
import { Link } from "react-router-dom";
import UserDetailModal from "./modals/user-detail";
import dayjs from "dayjs";
import WhatsappTemplateModal from "../../customers/modals/whatsapp-template";

const cardData = [
  {
    id: 1,
    rating: "4.5",
    name: "Patric Watson",
    status: "Paid on",
    edition: "BHX Bali 8",
    dueDate: "May 17, 2024",
  },
  {
    id: 2,
    rating: "4.5",
    name: "Patric Watson",
    status: "Paid on",
    edition: "BHX Bali 8",
    dueDate: "May 17, 2024",
  },
  // Add more card objects as needed
];

export default function OnboardingCalls() {
  const [activeTab, setActiveTab] = useState(1);

  const [user_data, set_user_data] = useState({});
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);
  const [editionsList, setEditionsList] = useState([]);
  const [filterData, setFilterData] = useState({
    // edition: "66e947c95d2d24c2274b73e1",
    edition: null,
  });

  const [count, setCount] = useState({ onBoardingCalls: 0, completedCalls: 0 });

  const [showCallsModal, setShowCallsModal] = useState(false);
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [notesOptions, setNotesOptions] = useState([]);
  const [whatsappTemplateModal, setWhatsAppTemplateModal] = useState(false);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    getNotesOptions();
    getEditions();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, filterData, activeTab]);

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getNotesOptions = () => {
    fetchData(`api/note/notes-enum/`, "get").then((res) => {
      if (res.success) {
        setNotesOptions(res["note_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);

    fetchData(
      `api/booking/bookings-onboarding-call-list/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&edition_id=${filterData?.edition || ""}&is_on_boarding=${
        activeTab === 1 ? "" : true
      }`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        const countValue = res.count;
        setData(res.data);
        setCount((prevCount) => ({
          ...prevCount,
          onBoardingCalls: activeTab === 1 ? countValue : null,
          completedCalls: activeTab === 1 ? null : countValue,
        }));
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const tabs = [
    {
      id: 1,
      title: "Onboarding Calls",
      content: "Content for Onboarding Calls - 3 calls to be done.",
      description: `Total calls to be done - ${count?.onBoardingCalls || 0}`,
    },
    {
      id: 2,
      title: "Calls Done",
      content: "Content for Calls Done - 1733 users onboarded.",
      description: `User onboarded - ${count?.completedCalls || 0}`,
    },
  ];

  return (
    <div>
      {/*begin::Followers toolbar*/}
      <div className="d-flex flex-wrap flex-stack  mb-6 ">
        <ul className="nav nav-tabs nav-pills flex-row border-0 flex-md-row me-5 mb-3 mb-md-0 fs-6 min-w-lg-200px">
          {tabs.map((tab) => (
            <li key={tab.id} className="nav-item me-0 mb-md-0 me-4">
              <div
                className={`nav-link px-10 btn btn-flex btn-active-primary btn-color-gray-800 btn-active-color-white ${
                  activeTab === tab.id ? "active" : ""
                }`}
                onClick={() => handleTabClick(tab.id)}
              >
                <span className="d-flex flex-column align-items-start">
                  <span className="fs-4 fw-bold">{tab.title}</span>
                  <span className="fs-7">{tab.description}</span>
                </span>
              </div>
            </li>
          ))}
        </ul>

        {/*begin::Controls*/}
        <div className="">
          {/*begin::Select*/}
          <Select
            value={filterData?.edition}
            onChange={(value) => {
              setFilterData((prevData) => ({
                ...prevData,
                edition: value,
              }));
            }}
            placeholder="Select Edition"
            style={{ width: 200 }}
            options={editionsList}
            allowClear
            size="large"
          />
          {/*end::Select*/}
        </div>
        {/*end::Controls*/}
      </div>
      {/*end::Followers toolbar*/}

      <div className="tab-content">
        {(activeTab === 1 || activeTab === 2) && (
          <div className="row g-6 mb-6 g-xl-9 mb-xl-9">
            {loading ? (
              <div className="my-10">
                <Loader cols={7} />
              </div>
            ) : (
              <>
                {data?.length > 0 ? (
                  <>
                    {data?.map((card) => (
                      <div className="col-md-6 col-xxl-4" key={card.id}>
                        {/*begin::Card*/}
                        <div className="card hover-elevate-up shadow-sm parent-hover">
                          <div className="card-body p-5">
                            <div className="d-flex flex-stack flex-row">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px me-5">
                                  <div className="symbol-label fs-5 fw-semibold bg-light-primary text-primary">
                                    {card.user.score}
                                  </div>
                                </div>
                                <div>
                                  <a
                                    href="#"
                                    className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
                                  >
                                    {card.user.first_name +
                                      " " +
                                      card?.user.last_name}
                                  </a>
                                  <div className="fw-semibold text-primary mb-0">
                                    {card.status_labels || "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="separator separator-dashed border-gray-300 my-4" />
                            <div>
                              <div className="row g-1">
                                <div className="col-md-6 d-flex align-items-center">
                                  <span className="fw-semibold text-gray-800 fs-7 me-2">
                                    Edition:
                                  </span>
                                  <Tooltip title={card.edition_name || "-"}>
                                    <Typography.Text
                                      ellipsis={true}
                                      className="fw-bolder d-inline-block text-truncate"
                                      style={{ maxWidth: "150px" }} // Adjust maxWidth as needed
                                    >
                                      {card.edition_name || "-"}
                                    </Typography.Text>
                                  </Tooltip>
                                </div>

                                <div className="col-md-6">
                                  <span className="fw-semibold text-gray-800 fs-7">
                                    Due on:{" "}
                                    <span className="fw-bolder">
                                      {card.latest_due_on
                                        ? dayjs(card.latest_due_on).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="separator separator-dashed border-gray-300 my-4" />
                            <div className="d-flex flex-stack">
                              <div className="d-flex flex-shrink-0 justify-content-end">
                                <div
                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                  title="Flight Ticket Details"
                                >
                                  {/* card.is_flight_ticket */}
                                  <i
                                    className={` ki-airplane fs-2 ${
                                      card?.is_flight_ticket
                                        ? "ki-solid text-success"
                                        : "ki-outline text-gray-600"
                                    }`}
                                  />
                                </div>

                                <div
                                  data-bs-toggle="modal"
                                  data-bs-target="#kt_modal_2"
                                  title="Call User"
                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                  onClick={() => {
                                    set_user_data(card);
                                    setShowCallsModal(!showCallsModal);
                                  }}
                                >
                                  <i className="bi bi-telephone fs-2 text-info" />
                                </div>

                                <div
                                  id="kt_drawer_example_toggle"
                                  title="View Notes"
                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                  onClick={() => {
                                    set_user_data(card);

                                    setShowNotesDrawer(!showNotesDrawer);
                                  }}
                                >
                                  {/* card.is_read_love_note */}
                                  <i className="ki-outline ki-notepad fs-2 text-gray-800" />
                                </div>

                                <div
                                  data-bs-toggle="modal"
                                  data-bs-target="#kt_modal_3"
                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                  onClick={() => {
                                    set_user_data(card);
                                    setShowUserDetailsModal(!showCallsModal);
                                  }}
                                  title="View User Details"
                                >
                                  <i className="ki-outline ki-user fs-2 text-warning" />
                                </div>

                                {/* <a
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_3"
                              href="#"
                              className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                             
                            >
                              <i className="fa-solid fa-venus-mars fs-4 text-warning" />
                            </a> */}

                                <div>
                                  {card?.user.phone ? (
                                    <Popover
                                      content={
                                        <>
                                          <div>
                                            <Button
                                              type="link"
                                              className="text-dark fw-bold"
                                              onClick={() => {
                                                setWhatsAppTemplateModal(true);
                                                set_user_data(card);
                                              }}
                                            >
                                              WhatsApp Template
                                            </Button>
                                            <br />
                                            <Button
                                              type="link"
                                              className="text-dark fw-bold"
                                              href={`https://wa.me/${card?.user?.phone}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Open in WhatsApp
                                            </Button>
                                          </div>
                                        </>
                                      }
                                      trigger="hover"
                                    >
                                      <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                                        <i
                                          className="bi bi-whatsapp fs-4 text-success"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    </Popover>
                                  ) : (
                                    <div
                                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                      disabled
                                      style={{
                                        cursor: "not-allowed",
                                        opacity: "0.5",
                                      }}
                                    >
                                      <i className="bi bi-whatsapp fs-2 text-success" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*begin::Card body*/}
                        </div>
                        {/*end::Card*/}
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    className="row g-5 mb-0 gx-md-10 "
                    style={{ minHeight: "600px" }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className=" col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {showCallsModal && (
        <CallsModal
          show={showCallsModal}
          onHide={() => {
            set_user_data({});
            setShowCallsModal(false);
            setLoading(true);
            getData();
          }}
          data={user_data}
        />
      )}

      {showUserDetailsModal && (
        <UserDetailModal
          show={showUserDetailsModal}
          onHide={() => {
            // set_user_data({});
            setShowUserDetailsModal(false);
            // setLoading(true);
            // getData();
          }}
          data={user_data}
        />
      )}

      {showNotesDrawer && (
        <NotesDrawer
          show={showNotesDrawer}
          onHide={() => {
            // set_user_data({});
            setShowNotesDrawer(false);
            // setLoading(true);
            // getData();
          }}
          data={user_data}
          notesOptions={notesOptions}
        />
      )}

      {whatsappTemplateModal && (
        <WhatsappTemplateModal
          show={whatsappTemplateModal}
          onHide={() => {
            setWhatsAppTemplateModal(false);
          }}
          data={{
            user_data: user_data?.user,
          }}
        />
      )}
    </div>
  );
}
