// super admin, admin,ads

import BookingDetail from "../pages/bookings/bookings-page/details/index.js";
import { BookingsPage } from "../pages/bookings/index.js";
import {
  AdMetrics,
  BusinessMetrics,
  CallRequests,
  CallRecordings,
  CancellationPolicy,
  ChangeEdition,
  CurationCentral,
  CustomerDetails,
  Customers,
  Dashboard,
  FAQs,
  ForgotPassword,
  Login,
  ManageEditions,
  OnboardingCalls,
  Payments,
  Profile,
  RefundPolicy,
  Register,
  RoomManager,
  Settings,
  VisaCenter,
} from "../pages/index.js";
import AddOns from "../pages/manage/add-ons/index.jsx";
import Destination from "../pages/manage/destinations/index.jsx";
import {
  HandleEditions,
  ManageExperiences,
  ManageHotels,
  Projects,
} from "../pages/manage/index.js";
import CrmPage from "../pages/settings/crm/index.js";
import HotLeads from "../pages/settings/hot-leads/index.js";
import {
  ChangePassword,
  Feedback,
  ManageEmployee,
  SupportTickets,
} from "../pages/settings/index.js";
import Policy from "../pages/settings/policy/index.js";
import ProjectSettings from "../pages/settings/project-settings/index.js";
import Utm from "../pages/settings/utm/index.js";
import VisaGuidelines from "../pages/settings/visa-guidelines/index.jsx";
import Tribe from "../pages/Tribe/index.js";

export const Admin = [
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/manage/editions", element: <ManageEditions /> },
  { path: "/manage/projects", element: <Projects /> },
  { path: "/manage/editions/handle/", element: <HandleEditions /> },
  { path: "/manage/editions/handle/:id", element: <HandleEditions /> },
  { path: "/manage/add-ons", element: <AddOns /> },
  { path: "/manage/hotels", element: <ManageHotels /> },
  { path: "/manage/destinations", element: <Destination /> },
  { path: "/manage/experiences", element: <ManageExperiences /> },
  { path: "/customers", element: <Customers /> },
  { path: "/customers/details/:id", element: <CustomerDetails /> },
  { path: "/customers/curation-central/:id", element: <CurationCentral /> },
  { path: "/bookings/payments", element: <Payments /> },
  { path: "/bookings/bookings-page/:id", element: <BookingsPage /> },
  { path: "/bookings/bookings-detail-page/:id", element: <BookingDetail /> },
  { path: "/bookings/call-requests", element: <CallRequests /> },
  { path: "/bookings/call-recordings", element: <CallRecordings /> },
  { path: "/bookings/onboarding-calls", element: <OnboardingCalls /> },
  { path: "/bookings/change-edition", element: <ChangeEdition /> },
  { path: "/bookings/visa-center", element: <VisaCenter /> },
  { path: "/bookings/room-manager", element: <RoomManager /> },
  { path: "/settings/ad-metrics", element: <AdMetrics /> },
  { path: "/settings/business-metrics", element: <BusinessMetrics /> },
  { path: "/settings/cancellation-policy", element: <CancellationPolicy /> },
  { path: "/settings/faqs", element: <FAQs /> },
  { path: "/settings/refund-policy", element: <RefundPolicy /> },
  { path: "/settings/change-password", element: <ChangePassword /> },
  { path: "/settings/employee", element: <ManageEmployee /> },
  { path: "/settings/project-settings", element: <ProjectSettings /> },
  { path: "/settings/feedback/:id", element: <Feedback /> },
  { path: "/settings/visa_guideline", element: <VisaGuidelines /> },
  { path: "/settings/support_tickets", element: <SupportTickets /> },
  { path: "/settings", element: <Settings /> },
  { path: "/tribe", element: <Tribe /> },
  { path: "/settings/hot-leads", element: <HotLeads /> },
  { path: "/settings/crm", element: <CrmPage /> },
  { path: "/settings/policy", element: <Policy /> },
  { path: "/settings/utm", element: <Utm /> },
  { path: "/my-profile", element: <Profile /> },

  //   { path: "/login", element: <Login /> },
  //   { path: "/register", element: <Register /> },
  //   { path: "/forgot-password", element: <ForgotPassword /> },
];
