import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "../../../components";

export default function FeedbackModal({
  show,
  feedbackQlist,
  feedbacklist,
  onHide,
  data,
}) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [feedbackData, setFeedbackData] = useState({
    que_1: null,
    que_2: null,
    que_3: null,
  });

  // Fill the state with fetched answers
  // useEffect(() => {
  //   const initialFeedback = {};
  //   feedbacklist.forEach((item) => {
  //     const questionKey = feedbackQlist.find(
  //       (q) => q.id === item.question
  //     )?.key;
  //     if (questionKey) {
  //       initialFeedback[questionKey] = item.answer;
  //     }
  //   });
  //   setFeedbackData(initialFeedback);
  // }, []);

  const onSubmitUser = async () => {
    setSending(true);

    // Build the feedback_data array
    const feedbackDataFormatted = feedbackQlist.map((question) => ({
      question: question.id,
      answer: feedbackData[question.key],
    }));

    const body = {
      user: data?.uuid,
      // "edition": "66d19819c7d1bc0cd9cbccce",
      feedback_data: feedbackDataFormatted,
    };

    fetchData(`api/feedback/`, "post", body).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFeedbackDataValue = (key, value) => {
    const common_data = {
      ...feedbackData,
      [key]: value,
    };

    setFeedbackData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h3 className="modal-title">Subjective Feedback</h3>
            <small className="fs-6">
              Feedback for BHX Prague &amp; Berlin 4
            </small>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="scroll-y pe-7 ps-3">
          <div className="row g-5 mb-0 align-items-center">
            {feedbackQlist?.map(({ id, question, key }) => (
              <div className="col-md-12" key={id}>
                <label htmlFor={id} className="fs-6 fw-bold mb-2">
                  {question}
                </label>
                <textarea
                  className="form-control fs-7"
                  id={id}
                  cols={30}
                  rows={3}
                  value={feedbackData[key]}
                  placeholder="What is the friction? Mood? What they aren't convinced about?"
                  onChange={(e) => setFeedbackDataValue(key, e.target.value)}
                />
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            toast.loading("Upadating Feedback");
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
