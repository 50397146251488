import React from "react";
const tab3Data = [
  {
    user: {
      name: "Khushbu Khetan",
      avatar: "/media/img.jpg",
    },
    paidOn: "Jan 21, 2024",
    editionChanges: "-",
    score: 3.5,
    payments: {
      total: "₹1,25,000",
    },
    visaStatus: "-",
    flight: "-",
    preferences: true, // represents the check icon
    esStatus: "Move to other...",
    poc: "Ruchir",
  },
  // You can add more rows with similar structure here
];
const InventoryManager = () => {
  return (
    <div>
      <div className="table-responsive tbl-sales">
        {/*begin::Table*/}
        <table
          className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d"
          id
        >
          <thead>
            <tr className="fw-bolder text-gray-900 bg-light">
              <th className="min-w-100px">User</th>
              <th className="min-w-100px">Paid on</th>
              <th className="min-w-125px">Edition Changes</th>
              <th className="min-w-100px">Score</th>
              <th className="min-w-150px">Payments</th>
              <th className="min-w-100px">Visa Status</th>
              <th className="min-w-100px">Flight</th>
              <th className="min-w-125px">Preferences</th>
              <th className="min-w-125px">ES Status</th>
              <th className="min-w-125px">POC</th>
              <th className="min-w-100px">Action</th>
            </tr>
          </thead>
          <tbody className="fw-semibold fs-7">
            {tab3Data.map((row, index) => (
              <tr key={index}>
                <td className="bdr-left-user">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-30px overflow-hidden me-3">
                      <img src={row.user.avatar} alt={row.user.name} />
                    </div>
                    <div className="d-flex flex-column text-start">
                      <a
                        href="#"
                        className="text-primary mb-1 fw-bolder fs-6 min-w-150px"
                      >
                        {row.user.name}
                      </a>
                    </div>
                  </div>
                </td>
                <td>{row.paidOn}</td>
                <td>{row.editionChanges}</td>
                <td>{row.score}</td>
                <td>
                  <div className="fw-bolder fs-6 text-gray-900">
                    <small>Total:</small> {row.payments.total}
                  </div>
                </td>
                <td>{row.visaStatus}</td>
                <td>{row.flight}</td>
                <td>
                  {row.preferences ? (
                    <i className="ki-outline ki-check fs-2 text-gray-800" />
                  ) : (
                    "-"
                  )}
                </td>
                <td>{row.esStatus}</td>
                <td>
                  <div className="fw-bold fs-6">{row.poc}</div>
                </td>
                <td />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InventoryManager;
