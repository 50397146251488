import { ManageEditions, Payments, Profile } from "../pages/index.js";
import AddOns from "../pages/manage/add-ons/index.jsx";
import Destination from "../pages/manage/destinations/index.jsx";
import {
  HandleEditions,
  ManageHotels,
  Projects,
} from "../pages/manage/index.js";

export const Social = [
  { path: "/manage/editions", element: <ManageEditions /> },
  { path: "/manage/projects", element: <Projects /> },
  { path: "/manage/editions/handle/", element: <ManageEditions /> },
  { path: "/manage/editions/handle/:id", element: <HandleEditions /> },
  { path: "/manage/add-ons", element: <AddOns /> },
  { path: "/manage/hotels", element: <ManageHotels /> },
  { path: "/manage/destinations", element: <Destination /> },
  { path: "/bookings/payments", element: <Payments /> },
  { path: "/my-profile", element: <Profile /> },
];
