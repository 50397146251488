import React from "react";
import Modal from "react-bootstrap/Modal";

export default function MaxSpots({ show, onHide }) {
  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header>
        <h3 className="modal-title">Max Spots (Editions)</h3>
        {/*begin::Close*/}
        <div>
          {/*begin::Destination*/}
          <div className="d-flex align-items-center fw-bold ">
            <div className="min-w-200px">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label text-gray-900 fw-bold"
                  htmlFor="flexCheckDefault"
                >
                  Show Old Projects
                </label>
              </div>
            </div>
            {/*begin::Label*/}
            <div className="text-gray-500 fs-7 me-2 min-w-90px ">
              Select Project
            </div>
            {/*end::Label*/}
            {/*begin::Select*/}
            <select className="form-select form-select-transparent text-graY-800 fs-base lh-1 fw-bold py-0 ps-3 ">
              <option value="Show All" selected>
                Show All
              </option>
              <option value="a">BHX</option>
            </select>
            {/*end::Select*/}
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2 w-70px"
              onClick={onHide}
            >
              <i className="ki-outline ki-cross fs-1" />
            </div>
          </div>
          {/*end::Destination*/}
        </div>
      </Modal.Header>
      <div>
        <div className="modal-body pb-7 pt-0 ps-lg-7 pe-0">
          <div className="scroll-y pe-7 ps-3">
            <div className="row g-5 mb-5 align-items-center">
              <div className="col-md-12">
                <div className="main-box">
                  {/*begin::Table container*/}
                  <div className="table-responsive tbl-sales">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                      id
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-white">
                          <th className="min-w-100px p-0" />
                          <th className="min-w-100px p-0" />
                          <th className="min-w-100px p-0" />
                          <th className="min-w-100px p-0" />
                        </tr>
                      </thead>
                      <tbody className=" fw-semibold fs-7">
                        <tr className="bg-light">
                          <td className="bdr-left-user ">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column text-start">
                                <a
                                  href
                                  className="text-primary mb-1 fw-bolder fs-6 min-w-200px"
                                >
                                  BHX Prague &amp; Berlin 4
                                </a>
                                <div className="text-gray-800">
                                  <span>M:13</span>
                                  <span>F:9</span> <span>O:0</span> /{" "}
                                  <span>Ratio: 1.4</span>
                                </div>
                                <div className="text-gray-500">
                                  Apr 19, 2024
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-gray-800 fw-bold mt-1">
                              Max Spots
                            </div>
                            <div className="position-relative w-150px">
                              <input
                                type="text"
                                className="form-control form-control-sm border-0 w-150px"
                                defaultValue={20}
                              />
                              <i
                                className="ki-outline ki-information-3 fs-2 text-dark position-absolute top-50 translate-middle-y me-5 end-0"
                                data-bs-toggle="tooltip"
                                title="Last Updated:"
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              <span className="badge badge-outline badge-primary mt-0">
                                Overspill:
                                <span className="fw-bolder text-dark ms-1">
                                  20/20
                                </span>
                              </span>
                            </div>
                          </td>
                          <td>
                            <span className="btn btn-primary btn-sm fs-7 fw-bold">
                              <i className="ki-outline ki-notepad-edit fs-2" />{" "}
                              Update
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-light">
                          <td className="bdr-left-user ">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column text-start">
                                <a
                                  href
                                  className="text-primary mb-1 fw-bolder fs-6 min-w-200px"
                                >
                                  BHX Prague &amp; Berlin 4
                                </a>
                                <div className="text-gray-800">
                                  <span>M:13</span>
                                  <span>F:9</span> <span>O:0</span> /{" "}
                                  <span>Ratio: 1.4</span>
                                </div>
                                <div className="text-gray-500">
                                  Apr 19, 2024
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-gray-800 fw-bold mt-1">
                              Max Spots
                            </div>
                            <div className="position-relative w-150px">
                              <input
                                type="text"
                                className="form-control form-control-sm border-0 w-150px"
                                defaultValue={20}
                              />
                              <i
                                className="ki-outline ki-information-3 fs-2 text-dark position-absolute top-50 translate-middle-y me-5 end-0"
                                data-bs-toggle="tooltip"
                                title="Last Updated:"
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              <span className="badge badge-outline badge-primary mt-0">
                                Overspill:
                                <span className="fw-bolder text-dark ms-1">
                                  20/20
                                </span>
                              </span>
                            </div>
                          </td>
                          <td>
                            <span className="btn btn-primary btn-sm fs-7 fw-bold">
                              <i className="ki-outline ki-notepad-edit fs-2" />{" "}
                              Update
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-light">
                          <td className="bdr-left-user ">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column text-start">
                                <a
                                  href
                                  className="text-primary mb-1 fw-bolder fs-6 min-w-200px"
                                >
                                  BHX Prague &amp; Berlin 4
                                </a>
                                <div className="text-gray-800">
                                  <span>M:13</span>
                                  <span>F:9</span> <span>O:0</span> /{" "}
                                  <span>Ratio: 1.4</span>
                                </div>
                                <div className="text-gray-500">
                                  Apr 19, 2024
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-gray-800 fw-bold mt-1">
                              Max Spots
                            </div>
                            <div className="position-relative w-150px">
                              <input
                                type="text"
                                className="form-control form-control-sm border-0 w-150px"
                                defaultValue={20}
                              />
                              <i
                                className="ki-outline ki-information-3 fs-2 text-dark position-absolute top-50 translate-middle-y me-5 end-0"
                                data-bs-toggle="tooltip"
                                title="Last Updated:"
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              <span className="badge badge-outline badge-primary mt-0">
                                Overspill:
                                <span className="fw-bolder text-dark ms-1">
                                  20/20
                                </span>
                              </span>
                            </div>
                          </td>
                          <td>
                            <span className="btn btn-primary btn-sm fs-7 fw-bold">
                              <i className="ki-outline ki-notepad-edit fs-2" />{" "}
                              Update
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {/*begin::Pagination*/}
          <div className="d-flex flex-stack flex-wrap pt-0 w-100">
            <div className="fs-6 fw-semibold text-gray-700">
              Showing 1 to 10 of 50 entries
            </div>
            {/*begin::Pages*/}
            <ul className="pagination">
              <li className="page-item previous">
                <a href="#" className="page-link">
                  <i className="previous" />
                </a>
              </li>
              <li className="page-item active">
                <a href="#" className="page-link">
                  1
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  2
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  3
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  4
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  5
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  6
                </a>
              </li>
              <li className="page-item next">
                <a href="#" className="page-link">
                  <i className="next" />
                </a>
              </li>
            </ul>
            {/*end::Pages*/}
          </div>
          {/*end::Pagination*/}
          {/* <button type="button" class="btn btn-light fs-7" data-bs-dismiss="modal">Close</button> */}
          {/* <button type="button" class="btn btn-primary fs-7">Submit</button> */}
        </div>
      </div>
    </Modal>
  );
}
