import React from "react";
import { Tabs } from "antd";
import RefundPolicy from "../refund-policy";
import CancellationPolicy from "../cancellation-policy";

const Policy = () => {
  const items = [
    {
      key: "1",
      label: "Refund Policy",
      children: <RefundPolicy />,
    },
    {
      key: "2",
      label: "Cancellation Policy",
      children: <CancellationPolicy />,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};

export default Policy;
