import { Spin } from "antd";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { Auth } from "./auth";
import { Admin } from "./admin";
import Layout from "../pages/layout";
import { Social } from "./social";

// Default Routes
const DefaultAdminPage = "/dashboard";
const DefaultSocialPage = "/manage/editions";
const DefaultProductionPage = "/dashboard";
const DefaultManagerPage = "/dashboard";
const DefaultCommsPage = "/manage/editions";

export const defaultRoutes = {
  1: DefaultAdminPage, // Admin
  2: DefaultAdminPage, // Ads
  3: DefaultSocialPage, // Manager
  6: DefaultAdminPage, // Business
  8: DefaultSocialPage, // Social
  9: DefaultCommsPage, // Comms
  10: DefaultProductionPage, // Production
};

const pages = {
  1: Admin, // Admin - all access
  2: Admin, // Ads - all access
  3: Admin, // Manager - all access
  6: Admin, // Business - all access
  8: Social, // Social - edition ,payment access
  9: Social.filter((d) => d.path !== "/bookings/payments"), // Comms - edition
  10: Admin.filter(
    (d) =>
      d.path !== "/settings/ad-metrics" &&
      d.path !== "/settings/business-metrics"
  ), // Production all access except ad-metrics and business-metrics
};

export default function Routers({ loading }) {
  const user = useSelector((state) => state.user);
  const userType = user?.user_type;

  //   console.log(userType, defaultRoutes[userType], pages[userType], "userType");

  const router = createBrowserRouter([
    {
      path: "/",
      element: userType ? (
        <Navigate to={defaultRoutes[userType]} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    ...Auth,
    ...(userType
      ? [
          {
            path: "/",
            element: (
              <Suspense fallback={"loading..."}>
                <Layout />
              </Suspense>
            ),
            children: (pages[userType] || []).map((route) => ({
              path: route.path,
              element: route.element,
            })),
          },
          {
            path: "*",
            element: <Navigate to="/" />,
          },
        ]
      : []),
    {
      path: "*",
      element: <Navigate to="/login" />,
    },
  ]);

  if (loading) {
    return <Spin />;
  }

  return <RouterProvider router={router} />;
}
