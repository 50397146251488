import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";

import moment from "moment";

import { Popover } from "antd";
import { LIMITS } from "../../../config";
import { fetchData } from "../../../config/service";
import { Loader, Pagination } from "../../../components";
import HotLeadsModal from "./modals";
import dayjs from "dayjs";
const hotLeadsData = [
  {
    name: "Shashank",
    edition: "BHX Bali 9",
    status: "Approved",
    trigger: "Crossed 3 Beta views on their dashboard",
    triggerDate: "October 3, 2024",
    leadAction: "",
    leadResponse: "",
    texted: "No",
    conversionTimeline: "No Payment yet",
    score: 4,
    actions: ["edit", "whatsapp"], // Actions are represented as icons
  },
  {
    name: "Shashank",
    edition: "BHX Bali 9",
    status: "Approved",
    trigger: "Confrimation payment failed",
    triggerDate: "August 14, 2024",
    leadAction: "Call Request Sent",
    leadResponse: "Declined",
    texted: "Yes",
    conversionTimeline: "No Payment yet",
    score: 4,
    actions: ["edit", "whatsapp"],
  },
  {
    name: "John Doe",
    edition: "XYZ 123",
    status: "Pending",
    trigger: "Downloaded lead magnet",
    triggerDate: "November 15, 2024",
    leadAction: "Email Sent",
    leadResponse: "Opened",
    texted: "No",
    conversionTimeline: "7 days follow up",
    score: 3,
    actions: ["edit", "whatsapp"],
  },
  {
    name: "Alice Smith",
    edition: "ABC 456",
    status: "Contacted",
    trigger: "Visited landing page",
    triggerDate: "December 20, 2024",
    leadAction: "Call Made",
    leadResponse: "Interested",
    texted: "Yes",
    conversionTimeline: "Pending payment",
    score: 5,
    actions: ["edit", "whatsapp"],
  },
];

export default function HotLeads() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [cities, setCities] = useState([]);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [hotLeadModal, setHotLeadModal] = useState(false);
  const [tribeMemberModal, setTribeMemberModal] = useState(false);
  const [tribeBlogImagesModal, setTribeBlogImagesModal] = useState(false);

  const [modalData, setModalData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  useEffect(() => {
    getData();
    // getCities();
  }, []);

  const getCities = () => {
    fetchData(
      `api/country/state/city/?phone_code=91&limit=${1000}&offset=${
        (activePage - 1) * limit
      }
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e.name,
            value: e.id,
          };
        });
        setCities(data);
      } else {
        setCities([]);
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/hot-leads/?limit=${limit}&offset=${(activePage - 1) * limit}
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteItem = () => {
    fetchData(`api/tribe/${modalData.id}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const tagLabels = {
    1: "Invite Only",
    2: "Buy Your Own Ticket",
  };

  return (
    <div className="row g-5 g-xxl-10">
      <div className="col-xxl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header">
            <div className="d-flex flex-stack flex-wrap gap-4">
              {/* <div className="position-relative mr-4">
                <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                <input
                  type="text"
                  data-kt-table-widget-4="search"
                  className="form-control text-dark w-250px fs-7 ps-12 all-search"
                  name="all_search"
                  placeholder="Search Leads here..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div> */}
              <div className="position-relative mr-4">
                <span className="fs-2 fw-semibold">Hot Leads Details</span>
              </div>
            </div>
            {/* <div className="card-toolbar">
              <div
                className="btn btn-primary fs-7 fw-bold me-2"
                onClick={() => {
                  setTribeBlogImagesModal(!tribeBlogImagesModal);
                }}
              >
                Add Images
              </div>

              <button
                className="btn btn-primary fs-7 fw-bold"
                onClick={() => {
                  setHotLeadModal(!hotLeadModal);
                }}
              >
                <i className="ki-outline ki-plus-square fs-2" /> Add New Event
              </button>
            </div> */}
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-2 pb-7">
            {loading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {data?.length > 0 ? (
                  <>
                    <div className="table-responsive tbl-sales">
                      {/*begin::Table*/}
                      <table
                        className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                        id
                      >
                        <thead className="fw-bolder text-gray-900 bg-light-primary">
                          <tr>
                            <th className="min-w-150px">User</th>
                            <th className="min-w-175px">Edition</th>
                            <th className="min-w-150px">Manged By</th>
                            <th className="min-w-200px">Trigger</th>
                            <th className="min-w-150px">Trigger Date</th>
                            {/* <th className="min-w-125px">Lead Action</th> */}
                            <th className="min-w-125px">Lead Response</th>
                            <th className="min-w-175px">Notes</th>
                            <th className="min-w-100px">Reach Out?</th>
                            <th className="min-w-200px">Conversion Timeline</th>
                            <th className="min-w-50px">Score</th>
                            <th className="min-w-50px">Actions</th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold fs-7">
                          {data.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                    {/* <img
                                    src={
                                      item.user?.image || "/media/blank.png"
                                    }
                                    alt=""
                                  /> */}
                                  </div>
                                  <div className="d-flex flex-column">
                                    <div
                                      title="Click to View Tribe Members"
                                      className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-125px cursor-pointer"
                                      onClick={() => {
                                        setTribeMemberModal(!tribeMemberModal);
                                      }}
                                    >
                                      {item.user?.first_name &&
                                      item.user?.last_name
                                        ? `${item.user.first_name} ${item.user.last_name}`
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>{item.edition_name || "-"}</td>
                              <td>
                                {(item.managed_by &&
                                  item?.managed_by?.first_name +
                                    " " +
                                    item?.managed_by?.last_name) ||
                                  "-"}
                              </td>
                              <td>
                                {item?.lead_type === 1
                                  ? "If any user(who has sent an invite) clicks on any particular page more than 3 times"
                                  : item?.lead_type === 2
                                  ? "Curated but login into app after 14 days"
                                  : item?.lead_type === 3
                                  ? "First payment has been failed"
                                  : item?.lead_type === 4
                                  ? "Curated user Requested an edition change but have not paid"
                                  : "-"}
                              </td>
                              <td>
                                {dayjs(item.updated_at).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {item.customer_response == 1
                                  ? "Call Booked"
                                  : item.customer_response == 2
                                  ? "Declined"
                                  : item.customer_response == 3
                                  ? "No Response"
                                  : item.customer_response == 4
                                  ? "Closed"
                                  : "-"}
                              </td>
                              <td>{item.notes || "-"}</td>
                              <td
                                className={
                                  item.is_reach_out
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {item.is_reach_out ? "Yes" : "No"}
                              </td>
                              <td>{item.conversation_timeline || "-"}</td>
                              <td>{item.score || "0"}</td>
                              <td>
                                <div className="d-flex flex-shrink-0">
                                  <div
                                    className={`btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3 `}
                                    onClick={() => {
                                      setHotLeadModal(!hotLeadModal);
                                      setModalData(item);
                                    }}
                                  >
                                    <i className="ki-outline ki-pencil fs-2 text-dark" />
                                  </div>

                                  <div>
                                    {item?.user?.phone ? (
                                      <a
                                        href={`https://wa.me/${item.user.phone}`}
                                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Chat on Whatsapp"
                                      >
                                        <i className="bi bi-whatsapp fs-2 text-success" />
                                      </a>
                                    ) : (
                                      <div
                                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                                        disabled
                                        style={{
                                          cursor: "not-allowed",
                                          opacity: "0.5",
                                        }}
                                      >
                                        <i className="bi bi-whatsapp fs-4 text-success" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/*end::Table*/}
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={setLimit}
                      limit={limit}
                      data={data}
                      activePage={activePage}
                      totalRecords={totalRecords}
                      onChange={(pageNumber) => {
                        setLoading(true);
                        setActivePage(pageNumber);
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

      <HotLeadsModal
        show={hotLeadModal}
        onHide={() => {
          setHotLeadModal(false);
          setModalData({});
        }}
        data={modalData}
        cities={cities}
        onSuccess={() => {
          setLoading(true);
          getData();
        }}
      />
    </div>
  );
}
