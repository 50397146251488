import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Loader, Pagination } from "../../../components";
import { LIMITS } from "../../../config";
import dayjs from "dayjs";

export default function ManageEmployee() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);


  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/call/records/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        // Get the user ID from the state
        const userId = user?.uuid;
        const filteredData = res?.data?.filter((item) => item?.uuid !== userId);
        setData(filteredData);
        setTotalRecords(res.count - 1);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };
  return (
    <>
      <div className="row gy-5 g-xl-5">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <div className="card-toolbar gap-2">
              </div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="main-box clearfix">
                        <div className="table-responsive">
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                            <thead>
                              <tr className="fw-bolder text-dark bg-white">
                                <th className="min-w-75px text-center">#</th>
                                <th className="min-w-180px">User Name</th>
                                <th className="min-w-180px">User Number</th>
                                <th className="min-w-125px">Employee Name</th>
                                <th className="min-w-125px">Employee Number</th>
                                <th className="min-w-100px">Start Time</th>
                                <th className="min-w-175px">End Time</th>
                                <th className="min-w-90px">Duration</th>
                                {/* <th className="min-w-175px">status</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {data?.length > 0
                                ? data?.map((d, i) => {
                                  return (
                                    <tr className="bg-light" key={i}>
                                      <td className="bdr-left-user text-center">
                                        {(activePage - 1) * limit + 1 + i}
                                      </td>
                                      <td>{d?.customer || "-"}</td>
                                      <td>{d?.to_number || "-"}</td>
                                      <td>{d?.employee || "-"}</td>
                                      <td>{d?.from_number || "-"}</td>
                                      <td>{dayjs(d.start_time).format("DD-MM-YYYY h:mm a")}</td>
                                      <td>{dayjs(d.end_time).format("DD-MM-YYYY h:mm a")}</td>
                                      <td>{((d?.duration || 0) / 60).toFixed(2)} Minutes</td>
                                      {/* <td>{d?.status || "-"}</td> */}
                                      {/* <td>
                                        <a href={d.recording_url || "-"} className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                          target="_blank" rel="noopener noreferrer"><i class="las la-external-link-alt"></i></a>
                                      </td> */}
                                    </tr>
                                  );
                                })
                                : "No Data Found"}
                            </tbody>
                          </table>
                        </div>

                        <div className="separator separator-dashed border-gray-200" />
                        <Pagination
                          setLimit={setLimit}
                          limit={limit}
                          data={data}
                          activePage={activePage}
                          totalRecords={totalRecords}
                          onChange={(pageNumber) => {
                            setLoading(true);
                            setActivePage(pageNumber);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
