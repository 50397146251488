import React from "react";

const Active = () => {
  return (
    <div>
      <div>
        {/*begin::Card*/}
        <div className="card card-flush mb-6 mb-xl-9 ">
          {/*begin::Card header*/}
          <div className="card-header">
            {/*begin::Card title*/}
            <div className="card-title flex-column">
              <h2 className="mb-1">59 Visa Entries</h2>
            </div>
            {/*end::Card title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar"></div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          {/*begin::Body*/}
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-125px">Name</th>
                    <th className="min-w-90px">Days Left</th>
                    <th className="min-w-125px">Edition</th>
                    <th className="min-w-100px">Status</th>
                    <th className="min-w-175px">Visa Stage</th>
                    <th className="min-w-100px">Appoinment</th>
                    <th className="min-w-150px">Visa Status Upd.</th>
                    <th className="min-w-100px">Fee</th>
                    <th className="min-w-100px">Services</th>
                    <th className="min-w-100px">Action</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                          <img src="assets/media/blank.png" alt="" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                          >
                            Reema Singh
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>183</td>
                    <td>BCX Iceland 7</td>
                    <td>Not Coming</td>
                    <td>
                      <div className>
                        <div className="fw-bold text-primary">
                          S1: Appoinment Pending
                        </div>
                        <span className="fs-7 text-gray-600">
                          Start Process
                        </span>
                      </div>
                    </td>
                    <td>Jan 19, 2024</td>
                    <td>70 Days ago</td>
                    <td>INR 3500</td>
                    <td>
                      <span className="badge badge-light-success flex-shrink-0 align-self-center py-3 px-4 fs-7">
                        Full Board
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-shrink-0 ">
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-address-book fs-2 text-gray-900" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-file fs-2 text-primary" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-airplane fs-2 text-gray-900" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                        >
                          <i className="ki-outline ki-notepad fs-2 text-gray-900" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                          <img src="assets/media/blank.png" alt="" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                          >
                            Reema Singh
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>183</td>
                    <td>BCX Iceland 7</td>
                    <td>Not Coming</td>
                    <td>
                      <div className>
                        <div className="fw-bold text-primary">
                          S1: Appoinment Pending
                        </div>
                        <span className="fs-7 text-gray-600">
                          Start Process
                        </span>
                      </div>
                    </td>
                    <td>Jan 19, 2024</td>
                    <td>70 Days ago</td>
                    <td>INR 3500</td>
                    <td>
                      <span className="badge badge-light-success flex-shrink-0 align-self-center py-3 px-4 fs-7">
                        Full Board
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-shrink-0 ">
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-address-book fs-2 text-gray-900" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-file fs-2 text-primary" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-airplane fs-2 text-gray-900" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                        >
                          <i className="ki-outline ki-notepad fs-2 text-gray-900" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            {/*begin::Separator*/}
            <div className="separator separator-dashed border-gray-200 mb-n4" />
            {/*end::Separator*/}
            {/*begin::Pagination*/}
            <div className="d-flex flex-stack flex-wrap pt-10">
              <div className="fs-6 fw-semibold text-gray-700">
                Showing 1 to 10 of 50 entries
              </div>
              {/*begin::Pages*/}
              <ul className="pagination">
                <li className="page-item previous">
                  <a href="#" className="page-link">
                    <i className="previous" />
                  </a>
                </li>
                <li className="page-item active">
                  <a href="#" className="page-link">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    4
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    5
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    6
                  </a>
                </li>
                <li className="page-item next">
                  <a href="#" className="page-link">
                    <i className="next" />
                  </a>
                </li>
              </ul>
              {/*end::Pages*/}
            </div>
            {/*end::Pagination*/}
          </div>
          {/*begin::Body*/}
        </div>
      </div>
    </div>
  );
};

export default Active;
