import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";

import toast from "react-hot-toast";

import { DatePicker, message, Select, Tabs, TimePicker, Tooltip } from "antd";
import { fetchData } from "../../../../../config/service";
import { validateFields } from "../../../../../components/validation";
import moment from "moment";
import dayjs from "dayjs";

export default function GamePlanDayModal({
  show,
  onHide,
  gamePlanId,
  data,
  eventDate,
  disabledDate,
}) {
  const [sending, setSending] = useState(false);

  // State object for form fields
  const [formState, setFormState] = useState({
    day: "",
    date: "",
  });

  useEffect(() => {
    if (data?.id) {
      // console.log(data, "data");
      setFormState({
        day: "",
        date: "",
      });
    } else {
      setFormState({
        day: "",
        date: "",
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clearInput = () => {
    setFormState({
      day: "",
      date: "",
    });
  };

  const rules = {
    day: {
      required: true,
      type: "number",
      min: 1,
      max: 366,
      message: "Please enter a valid Day number",
    },
    date: {
      required: true,
    },
  };

  const onSubmit = () => {
    const { isValid, errors } = validateFields(formState, rules);

    if (!isValid) {
      return;
    } else {
      toast.loading("Adding day");
    }

    setSending(true);

    const body = {
      related_edition: data?.edition_id,
      date: formState.date || "",
      day_number: Number(formState.day) || "",
    };

    // console.log(body, "body");

    let api = data?.id
      ? `api/gameplan/gamesegments/${data?.id}/`
      : "api/gameplan/gamesegments/";
    let method = data?.id ? "put" : "post";

    fetchData(`api/gameplan/`, "POST", body).then((res) => {
      if (res.success) {
        toast.dismiss();
        onHide();
        clearInput();
        toast.success("Day added successfully");
      } else {
        toast.dismiss();
        toast.error(res.message);
      }
    });
  };

  return (
    <Modal centered show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <h3 className="modal-title">Add New GamePlan</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-3">
          <div className="col-md-12">
            <label className="fs-6 fw-bold mb-2">Day</label>
            <input
              type="number"
              name="day"
              value={formState.day}
              onChange={handleChange}
              className="form-control fs-7 w-100"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <label className="fs-6 fw-bold mb-2">Date</label>
            <DatePicker
              showTime={{
                format: "HH:mm",
                // defaultValue: dayjs().hour(14).minute(0),
              }}
              format="DD-MM-YYYY HH:mm"
              value={
                formState.date
                  ? dayjs(formState?.date) // Parse the existing start date using Day.js
                  : null
              }
              onChange={(date, dateString) => {
                const utcDateString = date
                  ? date.format("YYYY-MM-DD HH:mm")
                  : null;

                setFormState({
                  ...formState,
                  date: utcDateString ? utcDateString : null,
                });
              }}
              className="fs-2 w-100"
              placeholder="Pick a  Date"
              size="large"
              disabledDate={(current) =>
                disabledDate(
                  current,
                  "start",
                  null,
                  data?.startDate,
                  data?.endDate
                )
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => onSubmit()} // Handle form submission here
        >
          Add Game Plan
        </button>
      </Modal.Footer>
    </Modal>
  );
}
