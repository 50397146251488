import React from "react";
const tab2Data = [
  {
    user: {
      name: "BHX Prague & Berlin 4",
      avatar: "/media/img.jpg",
    },
    days: 97,
    paidOn: "Jan 21, 2024",
    editionChanges: "-",
    score: 3.5,
    feeForUser: {
      amount: "₹1,25,000",
      grant: "₹1,25,000",
    },
    calledBy: {
      name: "Ruchir",
      time: "Within 3 days",
    },
    onboarded: {
      time: "Within 8 days",
    },
    status: "Move to other...",
  },
  // Add more rows here as needed
];

const GuestList = () => {
  return (
    <div>
      <div className="table-responsive tbl-sales">
        {/*begin::Table*/}
        <table
          className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d"
          id
        >
          <thead>
            <tr className="fw-bolder text-gray-900 bg-light">
              <th className="min-w-100px">User</th>
              <th className="min-w-100px">Days</th>
              <th className="min-w-100px">Paid on</th>
              <th className="min-w-125px">Edition Changes</th>
              <th className="min-w-100px">Score</th>
              <th className="min-w-150px">Fee for User</th>
              <th className="min-w-100px">Called By</th>
              <th className="min-w-100px">Onboarded</th>
              <th className="min-w-125px">Status</th>
              <th className="min-w-100px">Action</th>
            </tr>
          </thead>
          <tbody className="fw-semibold fs-7">
            {tab2Data.map((row, index) => (
              <tr key={index}>
                <td className="bdr-left-user">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-30px overflow-hidden me-3">
                      <img src={row.user.avatar} alt={row.user.name} />
                    </div>
                    <div className="d-flex flex-column text-start">
                      <a
                        href="#"
                        className="text-primary mb-1 fw-bolder fs-6 min-w-200px"
                      >
                        {row.user.name}
                      </a>
                    </div>
                  </div>
                </td>
                <td>{row.days}</td>
                <td>{row.paidOn}</td>
                <td>{row.editionChanges}</td>
                <td>{row.score}</td>
                <td>
                  <div className="fw-bolder fs-6 text-gray-900">
                    {row.feeForUser.amount}
                    <br />
                    <small className="text-gray-600">
                      / Grant: {row.feeForUser.grant}
                    </small>
                  </div>
                </td>
                <td>
                  <div>
                    <div className="fw-bold fs-6">{row.calledBy.name}</div>
                    <div className="fs-8 text-gray-600">
                      {row.calledBy.time}
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div className="fw-bold fs-6"></div>
                    <div className="fs-8 text-gray-600">
                      {row.onboarded.time}
                    </div>
                  </div>
                </td>
                <td>{row.status}</td>
                <td />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GuestList;
