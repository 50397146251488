import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";

import { Select } from "antd";
import { fetchData } from "../../../../config/service";

export default function CallsModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [notesData, setNotesData] = useState({
    note_type: 5, //default set to 5 for onboarding related
    note: `Coming / Not Coming - 
Read Love Notes (Y/No/Why Not) - 
Join Whatsapp Group (Yes/No) - 
Second Payment Due Date - 
Payment Status (Doing/Will Do/Not Doing) - 
Passport is Valid (Y/N) - 
Visa Status (Applied/Not Applied/Complicated) - 
Booked Flight (y/n) - 
Travelling with a Partner (y/n) -`,
    unsure_coming: false,
  });

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      booking_id: data?.id,
      user: data?.user?.uuid,
      note: notesData?.note,
      note_type: notesData?.note_type,
      unsure_coming: notesData?.unsure_coming,
    };

    fetchData(`api/note/`, "post", body).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="modal-title">
            {data?.user?.first_name + " " + data?.user?.last_name}
          </h3>
          <div className="fs-7">
            Phone:{" "}
            <a href="" className="fw-bold">
              {data?.user?.phone || "-"}
            </a>
          </div>
          <div className="fs-7">
            City:{" "}
            <a href="" className="fw-bold">
              {data?.user?.user_profile?.city_name || "-"}
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-5 mb-5 align-items-center">
          <div className="col-md-12 fv-row">
            <form className="form" action="#" method="post">
              <label htmlFor="" className=" fs-6 fw-bold mb-0 ">
                Onboarding Call
              </label>
              <div className="fv-row mt-3">
                <p className="mb-1 fw-bold"> What to share?</p>
                <p className="mb-1">
                  {" "}
                  Welcome them to Tribe build their excitement Tell them about
                  Vibe Tribe
                </p>
              </div>
            </form>
          </div>
          <div className="col-md-12 fv-row">
            <label htmlFor="" className="fs-6 fw-bold mb-2">
              Things to assess
            </label>
            <textarea
              className="form-control fs-7"
              placeholder="Add Notes"
              cols={30}
              rows={10}
              value={notesData?.note}
              onChange={(e) =>
                setNotesData({ ...notesData, note: e.target.value })
              }
            />
          </div>
          <div className="col-md-12 fv-row">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id="flexCheckDefault"
                checked={notesData.unsure_coming}
                onChange={(e) =>
                  setNotesData({
                    ...notesData,
                    unsure_coming: e.target.checked,
                  })
                }
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Unsure of Coming?
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Mark as Done
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
