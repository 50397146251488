import React, { useEffect, useState } from "react";
import SelectReact from "react-select";

const customStyle = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#ffffff",
    border: "1px solid #DBDFE9",
    borderRadius: ".92rem",
    boxShadow: "none !important",
    minWidth: "150px",
    "*": {
      boxShadow: "none !important",
    },
    "&:hover": {
      border: "1px solid lightgray !important",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#7843e6" : "white",
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({
    ...styles,
    color: "#99A1B7",
    fontSize: ".95rem",
    fontWeight: "500",
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#4B5675",
    fontSize: ".95rem",
    fontWeight: "500",
  }),
};

export default function Select({
  list = [],
  value,
  label,
  selected,
  onChange,
  inModal,
  isClearable = false,
  defaultClearedValue = null,
}) {
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (list.length) {
      createOptions();
    } else {
      setOptions([]);
      setSelectedItem(null);
    }
  }, [list]);

  useEffect(() => {
    if (options.length && selected) {
      const found = options.find((item) => item.value === selected);
      if (found) {
        setSelectedItem(found);
      }
    }
  }, [selected, options]);

  const createOptions = () => {
    let array = [];
    list.map((item) => {
      array = [...array, { label: item[label], value: item[value] }];
    });
    setOptions(array);
  };

  const handleChange = (item) => {
    if (item) {
      onChange(item.value);
      setSelectedItem(item);
    } else {
      onChange(defaultClearedValue);
      setSelectedItem(null);
    }
  };

  return (
    <SelectReact
      options={options}
      styles={customStyle}
      value={selectedItem}
      onChange={handleChange}
      isClearable={isClearable}
      {...(inModal && {
        menuPosition: "fixed",
      })}
    />
  );
}
