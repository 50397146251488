import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { DatePicker, Select } from "antd";

import dayjs from "dayjs";
import { validateFields } from "../../../../components/validation";
import { fetchData } from "../../../../config/service";

export default function HotLeadsModal({
  show,
  onHide,
  data,
  cities,
  onSuccess,
}) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");

  const [formState, setFormState] = useState({
    didReachOut: null,
    response: "",
    notes: "",
  });

  const handleChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // Populate formState if data.id is present
  useEffect(() => {
    if (data.id) {
      setFormState({
        didReachOut: data?.is_reach_out,
        response: data?.customer_response,
        notes: data?.notes,
      });
    } else {
      setFormState({
        didReachOut: null,
        response: "",
        notes: "",
      });
    }
  }, [data]);

  const validationRules = {
    didReachOut: {
      required: false,
    },
    response: {
      required: true,
    },
    notes: {
      required: false,
    },
  };

  const onSubmitEvent = async () => {
    const { isValid } = validateFields(formState, validationRules);

    if (!isValid) {
      return;
    } else {
      if (data?.id) {
        toast.loading("Updating Event...");
      } else {
        toast.loading("Adding Event...");
      }
    }

    const body = {
      is_reach_out: formState.didReachOut,
      customer_response: formState.response,
      notes: formState.notes,
    };

    setSending(true);

    // console.log(body, "body");
    let api = data?.id ? `api/hot-leads/${data?.id}/` : "api/hot-leads/";
    let method = data?.id ? "put" : "post";

    fetchData(api, method, body, "", false).then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        toast.success("Success");
        onHide();
        onSuccess();
      } else {
        toast.error(res.message);
      }
    });
  };

  const options = [
    { label: "Call Booked", value: 1 },
    { label: "Declined", value: 2 },
    { label: "No Response", value: 3 },
    { label: "Closed", value: 4 },
  ];

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Action Center <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="card bg-light-dark mb-10">
          <div class="card-body p-4">
            <div class="d-flex flex-column gap-3">
              <div class="d-flex align-items-start">
                <span class="text-gray-600 fw-semibold me-2">
                  Phone Number:
                </span>
                <span class="text-gray-800 fw-bold">
                  {data.user?.phone || "-"}
                </span>
              </div>
              <div class="d-flex align-items-start">
                <span class="text-gray-600 fw-semibold me-2">Edition:</span>
                <span class="text-gray-800 fw-bold">
                  {data.edition_name || "-"}
                </span>
              </div>
              <div class="d-flex align-items-start">
                <span class="text-gray-600 fw-semibold me-2">Trigger:</span>
                <span class="text-gray-800 fw-bold">
                  {" "}
                  {data?.lead_type == 1
                    ? "If any user(who has sent an invite) clicks on any particular page more than 3 times"
                    : data?.lead_type == 2
                    ? "Curated but login into app after 14 days"
                    : data?.lead_type == 3
                    ? "First payment has been failed"
                    : data?.lead_type == 4
                    ? "Curated user Requested an edition change but have not paid"
                    : "-"}
                </span>
              </div>
              <div class="d-flex align-items-start">
                <span class="text-gray-600 fw-semibold me-2">
                  Trigger Date:
                </span>
                <span class="text-gray-800 fw-bold">
                  {dayjs(data.updated_at).format("DD-MM-YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <label class="form-label fw-bold mb-3">Did you reach out?</label>
          <div class="d-flex gap-5">
            <div class="form-check form-check-custom form-check-solid">
              <input
                class="form-check-input"
                type="radio"
                id="yes"
                name="didReachOut"
                value="yes"
                checked={formState.didReachOut === true}
                onChange={() => handleChange("didReachOut", true)}
              />
              <label class="form-check-label ms-2" for="yes">
                Yes
              </label>
            </div>
            <div class="form-check form-check-custom form-check-solid">
              <input
                class="form-check-input"
                type="radio"
                id="no"
                name="didReachOut"
                value="no"
                checked={formState.didReachOut === false}
                onChange={() => handleChange("didReachOut", false)}
              />
              <label class="form-check-label ms-2" for="no">
                No
              </label>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <label class="form-label fw-bold mb-3">Response</label>
          <Select
            className="w-100 fs-7"
            value={formState.response}
            onChange={(value) => handleChange("response", value)}
            options={options}
            size="large"
            placeholder="Select an option"
            dropdownStyle={{ zIndex: 1111 }}
          />
        </div>

        <div class="mb-5">
          <label class="form-label fw-bold mb-3">Notes</label>
          <textarea
            class="form-control "
            rows="4"
            value={formState.notes}
            onChange={(e) => handleChange("notes", e.target.value)}
            placeholder="Add detailed notes here..."
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitEvent();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
