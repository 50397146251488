import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { DatePicker, Select, TimePicker } from "antd";
import { fetchData } from "../config/service";

export default function EmailTemplateModal({ show, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageData, setMessageData] = useState({
    template: null,
    message: "",
    scheduleDate: null,
    scheduleTime: null,
  });
  const [templateList, setTemplateList] = useState([]);
  const [showScheduler, setShowScheduler] = useState(false);

  const setMessageDataValue = (field, value) => {
    setMessageData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  //   useEffect(() => {
  //     if (data) {

  //     }
  //   }, [data]);

  useEffect(() => {
    getEmailTemplate();
  }, []);

  useEffect(() => {
    if (messageData?.template) {
      getSingleEmailTemplate();
    }
  }, [messageData?.template]);

  const getEmailTemplate = () => {
    setLoading(true);
    fetchData(`api/dynamic-templates/`, "get").then((res) => {
      setLoading(false);
      if (res) {
        const data = res?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.template_name,
          };
        });

        setTemplateList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getSingleEmailTemplate = () => {
    setLoading(true);
    fetchData(
      `api/dynamic-templates/?id=${messageData?.template || ""}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res) {
        const msg = res?.data?.find(
          (item) => item?.id === messageData?.template
        );
        setMessageData((prev) => ({
          //   template: null,
          ...prev,
          message: msg?.message,
        }));
      } else {
        toast.error(res.message);
      }
    });
  };

  const onSubmit = async () => {
    setSending(true);

    const body = {
      id: messageData?.template,
      phone: data?.user_data?.phone,
      // phone: "+917436055117",
    };

    console.log(body, "body");

    // fetchData(`api/dynamic-templates/send-message/`, "post", body).then(
    //   (res) => {
    //     setSending(false);
    //     if (res.success) {
    //       console.log(res, "res");
    //       onHide();
    //       toast.success("Message Sent Successfully");
    //     } else {
    //       toast.error(res.message);
    //     }
    //   }
    // );
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Send Email Template <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9">
          <div className="col-12 mb-4">
            <div className="bg-light-secondary p-3 rounded">
              <div className="mb-2">
                <strong>Name:</strong>{" "}
                {data?.user_data?.first_name + data?.user_data?.last_name ||
                  "-"}
              </div>
              <div>
                <strong>Phone:</strong>
                {data?.user_data?.phone || "-"}
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <label className="required fw-semibold fs-6 mb-2">
              Pick Template
            </label>
            <Select
              placeholder="Select template"
              value={messageData.template}
              onChange={(e) => setMessageDataValue("template", e)}
              style={{ width: "100%" }}
              dropdownStyle={{ zIndex: 1111 }}
              options={templateList}
              size="large"
              disabled={true}
            />
          </div>

          <div className="col-12">
            <label className="required fw-semibold fs-6 mb-2">
              Review Message
            </label>
            <textarea
              className="form-control"
              rows={6}
              placeholder="Type here..."
              value={messageData.message}
              onChange={(e) => setMessageDataValue("message", e.target.value)}
            />
          </div>

          {showScheduler && (
            <div className="col-12 mb-4">
              <label className="fw-semibold fs-6 mb-2">Pick a Time</label>
              <div className="d-flex gap-3">
                <DatePicker
                  className="flex-grow-1"
                  value={messageData.scheduleDate}
                  onChange={(date) => setMessageDataValue("scheduleDate", date)}
                  format="MMMM D, YYYY"
                  size="large"
                />
                <TimePicker
                  value={messageData.scheduleTime}
                  onChange={(time) => setMessageDataValue("scheduleTime", time)}
                  format="HH:mm"
                  size="large"
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!showScheduler ? (
          <>
            <button
              type="button"
              className="btn btn-light-info fs-7"
              onClick={() => setShowScheduler(true)}
            >
              Send Later
            </button>
            <button
              type="button"
              className="btn btn-primary fs-7"
              onClick={onSubmit}
              disabled={sending}
            >
              Send Now
              {sending && (
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              )}
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-light fs-7"
              onClick={() => setShowScheduler(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary fs-7"
              onClick={onSubmit}
            >
              Schedule
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
