import React, { useEffect, useState } from "react";
import Manage from "./modal";
import { Alert, Loader, Pagination, Select } from "../../../components";
import { fetchData } from "../../../config/service";
import { LIMITS } from "../../../config";
import toast from "react-hot-toast";
import ImagePreview from "../../../components/validation";
import { Image } from "antd";

export default function Destination() {
  const [mangeModal, setManageModal] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [loading, setLoading] = useState(true);
  const [destinationList, setDestinationList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [destinationName, setDestinationName] = useState("");

  useEffect(() => {
    getDestination();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, destinationName]);

  const getDestination = () => {
    fetchData("api/destination/").then((res) => {
      if (res.success) {
        setDestinationList(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    if (destinationName) {
      param += `&destination=${encodeURIComponent(destinationName || "")}`;
    }

    fetchData(`api/experience/${param}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  const deleteItem = () => {
    fetchData(`api/experience/${selected.id}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-4 ">
          {/*begin::Tables Widget 9*/}
          <div
            className="card mb-5 mb-xl-8"
            style={{ position: "sticky", top: "50px" }}
          >
            {/*begin::Body*/}
            <div className="card-body ">
              <div className=" mb-7">
                <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center mb-2">
                  Add Inclusion &amp; Add Ons
                </h3>
                <div className="text-gray-600">
                  See Edition List with Images Make Images on Canva
                </div>
              </div>
              <div className="row g-5 mb-5 align-items-center">
                <div className="col-md-12 fv-row">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Name of Edition
                  </label>
                  <Select
                    value="id"
                    label="name"
                    list={[
                      { id: "1", name: "Chocolate" },
                      { id: "2", name: "Strawberry" },
                      { id: "3", name: "Vanilla" },
                    ]}
                  />
                  {/* <select
                    className="form-select fs-7"
                    data-control="select2"
                    data-placeholder="Choose an option"
                  >
                    <option />
                    <option value={1}>BHX Shillong</option>
                    <option value={2}>BHX Goa</option>
                  </select> */}
                </div>
                <div className="col-md-12 fv-row mt-7">
                  <button
                    className="btn btn-primary fs-7 fw-bold"
                    onClick={() => {
                      setManageModal(true);
                    }}
                  >
                    <i className="ki-outline ki-plus-square fs-2" /> Add More
                    Experiences
                  </button>
                </div>
              </div>
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
        <div className="col-xxl-8">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header ">
              <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center ">
                List Experiences
              </h3>
              <div className="card-toolbar">
                <div className="d-flex align-items-center position-relative">
                  <div className="ms-3">
                    <Select
                      isClearable
                      list={destinationList}
                      value="id"
                      label="name"
                      selected={destinationName}
                      onChange={(value) => {
                        setDestinationName(value);
                      }}
                    />
                  </div>
                </div>

                {/* <button class="btn btn-primary fs-7 fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_itinerary"><i class="ki-outline ki-plus-square fs-2"></i> Add Itinerary </button> */}
              </div>
            </div>
            {/*end::Header*/}
            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                          {/*begin::Table head*/}
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-50px">#</th>
                              <th className="min-w-100px">Image</th>
                              <th className="min-w-100px">
                                About the Experience
                              </th>
                              <th className="min-w-100px">Destinations</th>
                              <th className="min-w-100px text-end">Cost</th>
                              <th className="min-w-100px text-end">Action</th>
                            </tr>
                          </thead>
                          {/*end::Table head*/}
                          {/*begin::Table body*/}
                          <tbody>
                            {data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr className="bg-light" key={i}>
                                    <td className="bdr-left-user ">
                                      {(activePage - 1) * limit + ++i}
                                    </td>
                                    <td>
                                      <div className="symbol symbol-60px overflow-hidden me-3">
                                        <Image
                                          src={
                                            item?.default_image ??
                                            "/media/img.jpg"
                                          }
                                          alt={`${item.name} `}
                                          width={60}
                                          height={60}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <span className="fw-bold text-dark ms-0 fs-6">
                                        {item.about || "-"}
                                      </span>
                                    </td>
                                    <td>{item.destination_name}</td>
                                    <td className="text-end">
                                      {item.cost || "-"}
                                    </td>
                                    <td>
                                      <div className="d-flex flex-shrink-0 justify-content-end">
                                        <span
                                          title="Edit"
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm me-3"
                                          onClick={() => {
                                            setManageModal(true);
                                            setSelected(item);
                                          }}
                                        >
                                          <i className="ki-outline ki-pencil fs-2 text-dark" />
                                        </span>
                                        <span
                                          title="Delete"
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setSelected(item);
                                          }}
                                        >
                                          <i className="ki-outline ki-trash fs-2 text-danger" />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <span>No records</span>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="separator separator-dashed border-gray-200 " />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>
      <Manage
        item={selected}
        destinationList={destinationList}
        show={mangeModal}
        onHide={() => {
          setManageModal(false);
          setSelected(null);
        }}
        onSuccess={() => {
          getData();
        }}
      />
      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onSuccess={() => {
          getData();
        }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />
    </div>
  );
}
