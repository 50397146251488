import { Button, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import dayjs from "dayjs";

export default function Settings({
  editionsStatusOptions,
  show,
  onHide,
  data,
}) {
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    edition_status: null,
    restricted_gender: [],
  });
  const genderChoices = [
    { label: "Female", value: 1 },
    { label: "Male", value: 2 },
    { label: "Queer/Nonconforming", value: 3 },
    { label: "Trans", value: 4 },
    { label: "Prefer not to say", value: 5 },
  ];

  useEffect(() => {
    if (data) {
      setFormData({
        edition_status: data?.status?.id || null, // Set the status ID
        restricted_gender: data?.restricted_gender || [], // Set restricted gender
      });
      // console.log(data, "data");
    }
  }, [data]);

  const handleUpdate = () => {
    const body = {
      status: formData.edition_status,
    };

    fetchData(`api/editions/${data?.id}/`, "put", body, "", false).then(
      (res) => {
        toast.remove();
        setSending(false);
        if (res.success) {
          toast.success("Success");
          onHide(true);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const handleAdd = () => {
    const body = {
      restricted_gender: formData.restricted_gender,
    };

    fetchData(`api/editions/${data?.id}/`, "put", body, "", false).then(
      (res) => {
        toast.remove();
        setSending(false);
        if (res.success) {
          toast.success("Success");
          onHide(true);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  function getLabelsFromValues(choices, selectedValues) {
    return choices
      .filter((choice) => selectedValues.includes(choice.value)) // Filter matching values
      .map((choice) => choice.label) // Extract labels
      .join(", "); // Join into a single string
  }

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div>
          <h3 className="modal-title">{data?.name}</h3>
          <small className="fs-6">
            Start Date: {dayjs(data?.start_date).format("DD-MM-YYYY")}
          </small>
        </div>
      </Modal.Header>
      <div className="modal-body pb-7 pt-3 ps-lg-7 pe-0">
        <div
          className="scroll-y pe-7 ps-3"
          style={{
            height: "400px",
          }}
        >
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Change Edition Status
              </label>
              <Input.Group compact>
                <Select
                  showSearch
                  optionFilterProp="label"
                  value={formData?.edition_status}
                  onChange={(value) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      edition_status: value,
                    }))
                  }
                  placeholder="Choose Edition Status"
                  style={{ width: "70%" }}
                  dropdownStyle={{ zIndex: 1100 }}
                  options={editionsStatusOptions}
                  size="large"
                />
                <Button
                  size="large"
                  type="primary"
                  icon={<i className="ki-outline ki-plus-square fs-2" />}
                  style={{ width: "30%" }}
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </Input.Group>
            </div>
            <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Restricted Genders
              </label>
              <div className="bg-light p-3 min-h-50px">
                {data &&
                Array.isArray(data?.restricted_gender) &&
                data?.restricted_gender.length === 0
                  ? "No Restricted Gender"
                  : getLabelsFromValues(
                      genderChoices,
                      data?.restricted_gender || []
                    )}
              </div>
            </div>
            <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Add New Restricted gender
              </label>
              <Input.Group compact>
                <Select
                  mode="multiple"
                  showSearch
                  optionFilterProp="label"
                  name="restricted_gender"
                  value={formData?.restricted_gender}
                  onChange={(value) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      restricted_gender: value,
                    }))
                  }
                  placeholder="Choose Gender Status"
                  className="custom-multiple-select"
                  style={{ width: "80%" }}
                  dropdownStyle={{ zIndex: 1100 }}
                  size="large"
                  options={genderChoices}
                />
                <Button
                  size="large"
                  type="primary"
                  icon={<i className="ki-outline ki-plus-square fs-2" />}
                  style={{ width: "20%" }}
                  onClick={handleAdd}
                >
                  Add
                </Button>
              </Input.Group>
            </div>
            {/* <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Reset Ratio
              </label>
              <div className="mb-2">
                <small>
                  Effects upcoming curation, which means here on the curated
                  people will get adjusted price. This will reset score till
                  next sale only.
                </small>
              </div>
              <div className="mb-4">
                <small>
                  Ratio 2.01 and higher kicks in the dynamic pricing.
                </small>
              </div>
              <div className="input-group ">
                <input
                  type="number"
                  className="form-control fs-7"
                  defaultValue={2}
                />
                <button className="btn btn-light-primary border border-gray-300 min-w-125px fw-bold fs-7">
                  <i className="ki-outline ki-plus-square fs-2" /> Set Ratio
                </button>
              </div>
            </div> */}
            {/* <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Add Insight
              </label>
              <div className="input-group ">
                <input type="number" class="form-control fs-7" value="2"/>
                <textarea
                  name
                  id
                  cols={30}
                  rows={3}
                  className="form-control fs-7"
                  defaultValue={""}
                />
                <button class="btn btn-light-primary border border-gray-300 min-w-125px fw-bold fs-7"><i class="ki-outline ki-plus-square fs-2"></i> Set Ratio</button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Modal>
  );
}
