import ReactApexChart from "react-apexcharts";

export const AppChart = ({
  applicationChartLoading,
  applicationChartLeadLoading,
  applicationChartData,
  applicationChartLeadData,
}) => {
  // Transform the input data into the format ApexCharts expects
  const applicationChartDataLocal = {
    series: [
      {
        name: "Total Bookings",
        data: applicationChartData.map((item) => item.total_bookings),
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        area: {
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        show: false, // Hide the grid lines on both x and y axes
      },
      colors: ["#7843e6"],
      xaxis: {
        type: "datetime",
        categories: applicationChartData.map((item) => item.date), // Extract dates as labels
        // labels: {
        //   format: "yyyy-MM-dd", // Format if necessary
        // },
        // tickAmount: 7,
      },
      yaxis: {
        title: {
          text: "Bookings",
        },
      },
      title: {
        text: "Bookings Over The Time",
        align: "right",
      },
      stroke: {
        curve: "smooth",
      },
    },
  };

  const applicationLeadChartDataLocal = {
    series: [
      {
        name: "Total Bookings",
        data: applicationChartLeadData.map((item) => item.total_bookings),
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false, // Hide the grid lines on both x and y axes
      },
      colors: ["#17c653"],
      xaxis: {
        type: "datetime",
        categories: applicationChartLeadData.map((item) => item.date), // Extract dates as labels
        // labels: {
        //   format: "yyyy-MM-dd", // Format if necessary
        // },
      },
      yaxis: {
        title: {
          text: "Bookings",
        },
      },
      title: {
        text: "Bookings Lead Over The Time",
        align: "right",
      },
      stroke: {
        curve: "smooth", // Optional: For smoother lines
      },
    },
  };

  const chartTwoOptions = {
    chart: {
      id: "line-chart-2",
    },
    grid: {
      show: false, // Hide the grid lines on both x and y axes
    },
    colors: ["#7843e6"], // Color for the second chart's line
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
  };

  const chartTwoSeries = [
    {
      name: "Series 2",
      data: [23, 42, 30, 47, 35, 60],
    },
  ];

  return (
    <div className="min-h-800px">
      <div className="p-5">
        {applicationChartLoading ? (
          <>
            <div className="d-flex justify-content-center align-items-center w-100 h-300px">
              <div className="spinner-border text-primary" />
            </div>
          </>
        ) : (
          <>
            <h2>Application Chart</h2>
            {applicationChartData.length > 0 ? (
              <ReactApexChart
                options={applicationChartDataLocal?.options}
                series={applicationChartDataLocal?.series}
                type="line"
                height={300}
              />
            ) : (
              <div className="row g-5 mb-0 gx-md-10">
                <div className="col-md-12 text-center">
                  <div className="mt-0">
                    <img
                      src="/media/no-data-found.png"
                      className="mw-25"
                      alt="No Data Found"
                    />
                  </div>
                  <h1 className="mt-4">No Data Found</h1>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="p-5 my-5">
        {applicationChartLeadLoading ? (
          <>
            <div className="d-flex justify-content-center align-items-center w-100 h-300px">
              <div className="spinner-border text-primary" />
            </div>
          </>
        ) : (
          <>
            <h2>Application Lead Chart</h2>
            {applicationChartLeadData.length > 0 ? (
              <ReactApexChart
                options={applicationLeadChartDataLocal?.options}
                series={applicationLeadChartDataLocal?.series}
                type="line"
                height={300}
              />
            ) : (
              <div className="row g-5 mb-0 gx-md-10">
                <div className="col-md-12 text-center">
                  <div className="mt-0">
                    <img
                      src="/media/no-data-found.png"
                      className="mw-25"
                      alt="No Data Found"
                    />
                  </div>
                  <h1 className="mt-4">No Data Found</h1>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
