import React from "react";
import { Loader } from "../../../../components";

const Overview = ({ dashboardLoading, dashboardData }) => {
  return (
    <div>
      {/*begin:::Tab pane*/}
      <div>
        {/*begin::Card*/}
        <div className="card card-flush mb-6 mb-xl-9">
          {/*begin::Card header*/}
          <div className="card-header">
            {/*begin::Card title*/}
            <div className="card-title flex-column">
              <h2 className="mb-1">Overview</h2>
            </div>
            {/*end::Card title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar"></div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body p-9 pt-2">
            {dashboardLoading ? (
              <Loader cols={1} />
            ) : (
              <div className=" row g-5 gx-10">
                <div className="col-md-6 ">
                  <div className="m-0">
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-800 fw-bold text-hover-primary fs-6"
                          >
                            S1: Appoinment Pending
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-primary">
                            {`${
                              dashboardData?.stage_counts?.[
                                "Appointment Pending"
                              ] || 0
                            } Apps`}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-800 fw-bold text-hover-primary fs-6"
                          >
                            S2: Review
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-primary">
                            {`${
                              dashboardData?.stage_counts?.["Review"] || 0
                            } Apps`}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-800 fw-bold text-hover-primary fs-6"
                          >
                            S3: Submitted
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-primary">
                            {`${
                              dashboardData?.stage_counts?.["Submitted"] || 0
                            } Apps`}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-800 fw-bold text-hover-primary fs-6"
                          >
                            S4: Result
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-primary">
                            {`${
                              dashboardData?.stage_counts?.["Result"] || 0
                            } Apps`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="m-0">
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-800 fw-bold text-hover-primary fs-6"
                          >
                            S5: Not Coming
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-primary">
                            {`${
                              dashboardData?.stage_counts?.["Not Coming"] || 0
                            } Apps`}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-800 fw-bold text-hover-primary fs-6"
                          >
                            S6: Not Sure
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-primary">
                            {`${
                              dashboardData?.stage_counts?.["Not Sure"] || 0
                            } Apps`}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-800 fw-bold text-hover-primary fs-6"
                          >
                            S7: No Update on System
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-primary">
                            {`${
                              dashboardData?.stage_counts?.["No Update Yet"] ||
                              0
                            } Apps`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*end:::Tab pane*/}
    </div>
  );
};

export default Overview;
