import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../config/service";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { setUser } from "../../redux/slice";

export default function Profile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [work, setWork] = useState("");
  const [website, setWebsite] = useState("");
  const [bio, setBio] = useState("");
  const [sending, setSending] = useState(false);

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImage(file);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);

        // Call the API after the image preview is set
        toast.loading("Uploading Image...");
        uploadImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (imageFile) => {
    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      ...(imageFile && isURL(imageFile) ? {} : { photo: imageFile || "" }),
    };

    fetchData(`api/accounts/${user.uuid}/`, "put", body, "", true).then(
      (res) => {
        setSending(false);
        toast.remove();
        if (res.success) {
          // setIsEdit(false);
          dispatch(setUser({ ...user, ...res }));
          toast.success("Image Upload Successful");
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImagePreview(null);
  };

  useEffect(() => {
    if (user) {
      prefillInputs();
      setImagePreview(user?.photo);
      setImage(user?.photo);
    }
  }, [user, isEdit]);

  const prefillInputs = () => {
    setName(user?.first_name);
    if (user?.date_of_birth) {
      setBirthDate(moment(user?.date_of_birth));
    }

    const work = user?.user_work_details?.[0];
    if (work) {
      setWork(work?.name);
      setWebsite(work?.work_url);
      setDesignation(work?.designation);
    }

    setInstagram(user?.user_profile?.instagram_url);
    setLinkedin(user?.user_profile?.linkedin_url);
    setBio(user?.user_profile?.bio);
  };

  const onSubmit = () => {
    if (!name) {
      toast.error("Name can not be blank!");
    } else {
      setSending(true);

      const isURL = (str) => {
        try {
          new URL(str);
          return true;
        } catch {
          return false;
        }
      };

      const body = {
        first_name: name,
        date_of_birth: birthDate
          ? moment(birthDate).format("YYYY-MM-DD")
          : null,
        ...(work || website || designation
          ? {
              user_work_details: [
                {
                  name: work,
                  work_url: website,
                  designation: designation,
                },
              ],
            }
          : {}),
        ...(instagram || linkedin || bio
          ? {
              user_profile: {
                instagram_url: instagram,
                linkedin_url: linkedin,
                bio: bio,
              },
            }
          : {}),
        // ...(image && isURL(image) ? {} : { photo: image || "" }),
      };

      // console.log(body, "body");

      fetchData(`api/accounts/${user.uuid}/`, "put", body).then((res) => {
        setSending(false);
        if (res.success) {
          toast.success("Success");
          setIsEdit(false);
          dispatch(setUser({ ...user, ...res }));
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <div>
      {isEdit ? (
        <div className="card mb-5 mb-xl-10">
          <div className="card-header border-0 cursor-pointer">
            <div className="card-title m-0">
              <span
                className="btn btn-light-primary btn-sm fw-bold me-3 cursor-pointer"
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                <i className="ki-outline ki-arrow-left fs-2" /> Back
              </span>
              <h3 className="fw-bold m-0">Profile Details</h3>
            </div>
          </div>

          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <div className="form">
              <div className="card-body border-top p-9">
                <div className="row mb-6 align-items-center">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Avatar
                  </label>

                  <div className="col-lg-8">
                    <div
                      className="image-input image-input-outline"
                      data-kt-image-input="true"
                      style={{
                        backgroundImage: `url(${
                          imagePreview || "/media/blank.png"
                        })`,
                      }}
                    >
                      <div
                        className="image-input-wrapper w-125px h-125px"
                        style={{
                          backgroundImage: `url(${
                            imagePreview || "/media/blank.png"
                          })`,
                        }}
                      />

                      <label
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="Change avatar"
                      >
                        <i className="ki-outline ki-pencil fs-7" />

                        <input
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleImageChange}
                        />
                        <input type="hidden" name="avatar_remove" />
                      </label>

                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        title="Cancel avatar"
                      >
                        <i className="ki-outline ki-cross fs-2" />{" "}
                      </span>

                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title="Remove avatar"
                        onClick={handleRemoveImage}
                      >
                        <i className="ki-outline ki-cross fs-2" />{" "}
                      </span>
                    </div>

                    <div className="form-text">
                      Allowed file types: png, jpg, jpeg.
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Full Name
                  </label>

                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 fv-row">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Full name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Designation
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Designation"
                      value={designation}
                      onChange={(e) => {
                        setDesignation(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Date of Birth{" "}
                  </label>

                  <div className="col-lg-8 fv-row">
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        showDropdowns: true,
                        maxYear: parseInt(moment().format("YYYY"), 10),
                        locale: {
                          format: "DD-MM-YYYY",
                        },
                        maxDate: new Date(),
                      }}
                      onCallback={(start) => {
                        setBirthDate(start);
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pick a Date"
                        value={
                          birthDate
                            ? moment(birthDate).format("DD-MM-YYYY")
                            : ""
                        }
                      />
                    </DateRangePicker>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Instagram URL
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Instagram URL"
                      value={instagram}
                      onChange={(e) => {
                        setInstagram(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Linkedin URL
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Instagram URL"
                      value={linkedin}
                      onChange={(e) => {
                        setLinkedin(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Name of Company or Project
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company or Project Name"
                      value={work}
                      onChange={(e) => {
                        setWork(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Company Website or Portfolio Link
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add Link here"
                      value={website}
                      onChange={(e) => {
                        setWebsite(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Share a kickass description about yourself that encompasses
                    you, your work &amp; your adventures. Be crips.
                  </label>

                  <div className="col-lg-8 fv-row">
                    <textarea
                      className="form-control"
                      cols={30}
                      rows={3}
                      placeholder="For Ex. Jay is an experience designer with a strong desire to bring people closer to their passion"
                      value={bio}
                      onChange={(e) => {
                        setBio(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  className="btn btn-light btn-active-light-primary me-2 fs-7"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  Discard
                </button>
                <button
                  className="btn btn-primary fs-7"
                  onClick={onSubmit}
                  disabled={sending}
                >
                  {sending ? (
                    <span>
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
          <div className="card-header cursor-pointer">
            <div className="card-title m-0 flex-column d-flex">
              <h3 className="fw-bold mb-1">My Profile</h3>
              <div className="fs-7 text-gray-700">
                You are going to meet a bunch of Creators very soon. Tell them
                about yourself, so they can get to know you before the
                adventure.
              </div>
            </div>

            <span
              className="btn btn-sm btn-primary align-self-center cursor-pointer"
              onClick={() => {
                setIsEdit(true);
              }}
            >
              Edit Profile
            </span>
          </div>

          <div className="card-body p-9">
            <div className="row mb-8  align-items-center">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Profile Picture
              </label>

              <div className="col-lg-8">
                <div className="symbol symbol-100px symbol-lg-100px symbol-fixed position-relative">
                  <img
                    alt="Logo"
                    src={user?.photo ? user.photo : "/media/blank.png"}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Full Name
              </label>

              <div className="col-lg-8">
                <span className="fw-bold fs-5 text-gray-900">
                  {user?.first_name}
                </span>
              </div>
            </div>

            <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Email
              </label>

              <div className="col-lg-8 fv-row">
                <span className="fw-bold text-gray-900 fs-6">
                  {user?.email || "-"}
                </span>
              </div>
            </div>

            <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Mobile Number
              </label>

              <div className="col-lg-8 fv-row">
                <span className="fw-bold text-gray-900 fs-6">
                  {user?.phone || "-"}
                </span>
              </div>
            </div>

            <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Role
              </label>

              <div className="col-lg-8 fv-row">
                <span className="fw-bold text-gray-900 fs-6">
                  {user?.user_type == 1 ? "Admin" : "User"}
                </span>
              </div>
            </div>

            {/* <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Designation
              </label>

              <div className="col-lg-8 fv-row">
                <span className="fw-bold text-gray-900 fs-6">
                  {designation}
                </span>
              </div>
            </div>

            <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Date of Birth
              </label>

              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bold text-gray-900 fs-6">
                  {birthDate ? moment(birthDate).format("DD-MM-YYYY") : ""}
                </span>
              </div>
            </div>

            <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Instagram URL
              </label>

              <div className="col-lg-8">
                {instagram && (
                  <a
                    href={instagram}
                    target="_blank"
                    className="fw-semibold fs-6 text-hover-primary"
                  >
                    {instagram}
                  </a>
                )}
              </div>
            </div>

            <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Linkedin URL
              </label>

              <div className="col-lg-8">
                {linkedin && (
                  <a
                    href={linkedin}
                    target="_blank"
                    className="fw-semibold fs-6 text-hover-primary"
                  >
                    {linkedin}
                  </a>
                )}
              </div>
            </div>

            <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Name of Company or Project
              </label>

              <div className="col-lg-8">
                <span className="fw-bold fs-6 text-gray-900">{work}</span>
              </div>
            </div>

            <div className="row mb-10">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Company Website or Portfolio Link
              </label>

              <div className="col-lg-8">
                {website && (
                  <a href={website} target="_blank" className="fw-bold fs-6 ">
                    {website}
                  </a>
                )}
              </div>
            </div>

            <div className="row mb-8 ">
              <label className="col-lg-4 fs-6 fw-semibold text-gray-700">
                Share a kickass description about <br />
                yourself that encompasses you, your work <br />
                &amp; your adventures. Be crips.
              </label>

              <div className="col-lg-8">
                <span className="fw-bold fs-6 text-gray-900">{bio}</span>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
