import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";

import { Image, Select, Tooltip } from "antd";
import { fetchData } from "../../../../config/service";
import dayjs from "dayjs";
import { Loader } from "../../../../components";

export default function FlightModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });
  const [flightData, setFlightData] = useState([]);

  useEffect(() => {
    if (data) {
      getFlightData();
    }
  }, [data]);

  const getFlightData = () => {
    setLoading(true);
    fetchData(
      `api/travel_info/flight-ticket/details/?edition=${data?.edition}&user=${data?.user?.uuid}`,
      "get"
    ).then((res) => {
      setLoading(false);

      if (res.success) {
        console.log(res, "res");
        setFlightData(res);
      } else {
        toast.error(res.message);
      }
    });
  };

  const formatDate = (dateString) => {
    return dateString ? dayjs.utc(dateString).format("DD-MM-YYYY h:mm a") : "-";
  };

  return (
    // <Modal show={show} onHide={onHide} size="lg" centered backdrop="static"> prevent hide the modal
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Flight Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader cols={2} />
        ) : (
          <>
            {flightData?.edition !== "" &&
            flightData?.flight_ticket?.length > 0 ? (
              <>
                <div className="card card-custom gutter-b">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-40 symbol-light-primary me-5">
                        <span className="symbol-label">
                          <i className="ki-outline ki-airplane text-primary fs-1"></i>
                        </span>
                      </div>
                      <div className="d-flex flex-column flex-grow-1">
                        <h2 className="font-weight-bold text-dark mb-1">
                          {flightData?.flying_from_airport_name || "N/A"}
                        </h2>
                        <div className="d-flex justify-content-start gap-2 align-items-center">
                          <div className="fw-semibold text-muted fs-6 mb-1">
                            {flightData?.share_details
                              ? "Details Shared"
                              : "Details Not Shared"}
                          </div>
                          <div>
                            {flightData?.share_details ? (
                              <Tooltip title="Details Shared">
                                <i className="ki-outline ki-check-circle text-success fs-3"></i>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Details Not Shared">
                                <i className="ki-outline ki-minus-circle text-danger fs-3"></i>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                        {/* <span className="text-muted font-weight-bold">
                          {formatDate(flightData?.landing_date_time)}
                        </span> */}
                      </div>
                    </div>
                    <div className="separator separator-solid my-7"></div>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="d-flex align-items-center flex-lg-fill me-5 my-1">
                        <span className="me-4">
                          <i className="ki-outline ki-pin fs-1 text-muted "></i>
                        </span>
                        <div className="d-flex flex-column text-dark-75">
                          <span className="fw-semibold text-muted">From:</span>
                          <span className="fw-bold text-gray-800">
                            {flightData?.flying_from_airport_name || "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center flex-lg-fill me-5 my-1">
                        <span className="me-4">
                          <i className="ki-outline ki-calendar fs-1 text-muted"></i>
                        </span>
                        <div className="d-flex flex-column text-dark-75">
                          <span className="fw-semibold text-muted">
                            Landing Date:
                          </span>
                          <span className="fw-bold text-gray-800">
                            {formatDate(flightData?.landing_date_time)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-solid my-7"></div>

                    {/* <div className="d-flex justify-content-start gap-2 align-items-center">
                      <div className="fw-bold text-gray-800 fs-5 mb-1">
                        {flightData?.share_details
                          ? "Details Shared"
                          : "Details Not Shared"}
                      </div>
                      <div>
                        {flightData?.share_details ? (
                          <Tooltip title="Details Shared">
                            <i className="ki-outline ki-check-circle text-success fs-2"></i>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Details Not Shared">
                            <i className="ki-outline ki-minus-circle text-danger fs-2 "></i>
                          </Tooltip>
                        )}
                      </div>
                    </div>

                    <div className="separator separator-solid my-7"></div> */}

                    <h3 className="font-weight-bold text-dark mb-4">
                      Flight Tickets
                    </h3>
                    <div className="row">
                      {flightData?.flight_ticket?.length > 0 ? (
                        flightData.flight_ticket.map((ticket, index) => (
                          <div
                            key={ticket.id}
                            className="col-md-4 col-lg-3 mb-3"
                          >
                            <Image
                              src={ticket.url}
                              alt={`Flight Ticket ${index + 1}`}
                              className="w-100 rounded"
                              height={150}
                              width={150}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="col-12">
                          <p className="text-muted">
                            No flight tickets available.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row g-5 mb-0 gx-md-10">
                  <div className="col-md-12 text-center">
                    <div className="mt-0">
                      <img
                        src="/media/no-data-found.png"
                        className="mw-25"
                        alt="No Data Found"
                      />
                    </div>
                    <h1 className="mt-4">No Data Found</h1>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
