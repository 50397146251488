import React, { useEffect, useState } from "react";
import VisaFeeModal from "../../modals/visa-fee";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import { Image, Tooltip } from "antd";
import dayjs from "dayjs";
import { Loader } from "../../../../components";

const Visa = ({ editionData, id, edition_id }) => {
  const [isOpenVisaModal, setIsOpenVisaModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, [editionData]);

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/travel_info/flight-ticket/details/?edition=${edition_id}&user=${id}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      {" "}
      <div className="card card-flush mb-6 mb-xl-9">
        <div className="card-header">
          <div className="card-title flex-column">
            <h2 className="mb-1">Visa</h2>
          </div>

          <div className="card-toolbar"></div>
        </div>

        <div className="card-body p-9 pt-2">
          <div className=" row g-5 mb-0 gx-md-10">
            <div className="col-md-6">
              <div className="mt-0">
                <div className="mb-3">
                  {!editionData?.is_visa && (
                    <div
                      className="fs-6 fw-semibold text-primary cursor-pointer"
                      onClick={() => setIsOpenVisaModal(!isOpenVisaModal)}
                    >
                      Add Visa Fee
                    </div>
                  )}
                </div>
                <div className="mt-0">
                  <div className="mb-3">
                    <a href className="fs-6 fw-semibold">
                      Generate Hotel Vouchers
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5">
            {loading ? (
              <Loader cols={2} />
            ) : (
              <>
                {data?.edition !== "" && data?.flight_ticket?.length > 0 ? (
                  <>
                    <div className="separator separator-solid my-7"></div>
                    <div className="container">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-40 symbol-light-primary me-5">
                          <span className="symbol-label">
                            <i className="ki-outline ki-airplane text-primary fs-1"></i>
                          </span>
                        </div>
                        <div className="d-flex flex-column flex-grow-1">
                          <h2 className="font-weight-bold text-dark mb-1">
                            {data?.flying_from_airport_name || "N/A"}
                          </h2>
                          <div className="d-flex justify-content-start gap-2 align-items-center">
                            <div className="fw-semibold text-muted fs-6 mb-1">
                              {data?.share_details
                                ? "Details Shared"
                                : "Details Not Shared"}
                            </div>
                            <div>
                              {data?.share_details ? (
                                <Tooltip title="Details Shared">
                                  <i className="ki-outline ki-check-circle text-success fs-3"></i>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Details Not Shared">
                                  <i className="ki-outline ki-minus-circle text-danger fs-3"></i>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                          <span className="text-muted font-weight-bold">
                            {dayjs
                              .utc(data?.landing_date_time)
                              .format("DD-MM-YYYY h:mm a")}
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-solid my-7"></div>

                      <h3 className="font-weight-bold text-dark mb-4">
                        Flight Tickets
                      </h3>
                      <div className="row">
                        {data?.flight_ticket?.length > 0 ? (
                          data.flight_ticket.map((ticket, index) => (
                            <div
                              key={ticket.id}
                              className="col-md-4 col-lg-3 mb-3"
                            >
                              <Image
                                src={ticket.url}
                                alt={`Flight Ticket ${index + 1}`}
                                className="w-100 rounded"
                                height={150}
                                width={150}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="col-12">
                            <p className="text-muted">
                              No flight tickets available.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      {isOpenVisaModal && (
        <VisaFeeModal
          show={isOpenVisaModal}
          // notesOptions={notesOptions}
          onHide={() => {
            setIsOpenVisaModal(false);
            // setNotesLoading(true);
            // getNotes();
          }}
          data={{
            editionData: editionData,
            edition_id: edition_id,
            user_id: id,
          }}
        />
      )}
    </>
  );
};

export default Visa;
