import React from "react";

export default function CancellationPolicy() {
  return (
    <div>
      <div>
        <div className="mb-20 pb-lg-20">
          {/*begin::List*/}
          <ul className="fs-4 fw-semibold mb-6">
            <li>
              <span className="text-gray-700">
                90 days before: do whatever you want. Deposit will be retained.
              </span>
            </li>
            <li className="my-2">
              <span className="text-gray-700">
                45-89 days before start: 50% of Edition cost retained.
              </span>
            </li>
            <li>
              <span className="text-gray-700">
                30-45 days before start: 75% of Edition cost retained.
              </span>
            </li>
            <li className="my-2">
              <span className="text-gray-700">
                29-0 days before start: 100% of Edition cost retained.
              </span>
            </li>
            <li>
              <span className="text-gray-700">
                Cancellation charges exclude amendment fees and insurance
                premiums.
              </span>
            </li>
            <li className="my-2">
              <span className="text-gray-700">
                Partial cancellation may result in additional costs for
                remaining clients.
              </span>
            </li>
            <li>
              <span className="text-gray-700">
                Referral Discount or Any Discounts given will be pulled back.
              </span>
            </li>
            <li className="my-2">
              <span className="text-gray-700">
                100% cancellation charges for additional extras (e.g., single
                supplements, pre/post-tour nights) up to 90 days before start.
              </span>
            </li>
          </ul>
          {/*end::List*/}
        </div>
      </div>
    </div>
  );
}
