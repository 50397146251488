import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Loader, Pagination } from "../../../components";
import { LIMITS } from "../../../config";
import dayjs from "dayjs";

const dummyData = [
  {
    userName: "John Doe",
    emailId: "john.doe@example.com",
    edition: "Premium",
    source: "Google Ads",
    campaign: "Winter Sale",
    adSet: "AdSet A",
    ad: "Ad 1",
    tags: "sale, discount",
    clicks: 120,
    conversions: 10,
    LTV: "$500",
    date: "2025-01-25",
  },
  {
    userName: "Jane Smith",
    emailId: "jane.smith@example.com",
    edition: "Standard",
    source: "Facebook Ads",
    campaign: "Spring Promo",
    adSet: "AdSet B",
    ad: "Ad 2",
    tags: "promo, seasonal",
    clicks: 85,
    conversions: 15,
    LTV: "$400",
    date: "2025-01-24",
  },
];

export default function Utm() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);

  useEffect(() => {
    // getData();
  }, []);

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/call/records/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        // Get the user ID from the state
        const userId = user?.uuid;
        const filteredData = res?.data?.filter((item) => item?.uuid !== userId);
        setData(filteredData);
        setTotalRecords(res.count - 1);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <div className="row gy-5 g-xl-5">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                {/* <div className="position-relative mr-4">
                <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                <input
                  type="text"
                  data-kt-table-widget-4="search"
                  className="form-control text-dark w-250px fs-7 ps-12 all-search"
                  name="all_search"
                  placeholder="Search Event here..."
                  // value={searchTerm}
                  // onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div> */}
                <div className="position-relative mr-4"></div>
              </div>
              <div className="card-toolbar">
                <div
                  className="btn btn-primary fs-7 fw-bold me-2"
                  // onClick={() => {
                  //   setTribeBlogImagesModal(!tribeBlogImagesModal);
                  // }}
                >
                  Export
                </div>
              </div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {dummyData?.length > 0 ? (
                    <>
                      <div className="main-box clearfix">
                        <div className="table-responsive">
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                            <thead>
                              <tr className="fw-bolder text-dark bg-white">
                                <th className="min-w-75px text-center">#</th>
                                <th className="min-w-125px">User Name</th>
                                <th className="min-w-180px">Email ID</th>
                                <th className="min-w-125px">Edition</th>
                                <th className="min-w-125px">Source</th>
                                <th className="min-w-100px">Campaign</th>
                                <th className="min-w-175px">Ad Set</th>
                                <th className="min-w-175px">Ad</th>
                                <th className="min-w-90px">Tags</th>
                                <th className="min-w-90px">Clicks</th>
                                <th className="min-w-90px">Conversions</th>
                                <th className="min-w-90px">LTV</th>
                                <th className="min-w-90px">Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dummyData.map((d, i) => (
                                <tr className="bg-light" key={i}>
                                  <td className="text-center">
                                    {(activePage - 1) * limit + 1 + i}
                                  </td>
                                  <td>{d.userName}</td>
                                  <td>{d.emailId}</td>
                                  <td>{d.edition}</td>
                                  <td>{d.source}</td>
                                  <td>{d.campaign}</td>
                                  <td>{d.adSet}</td>
                                  <td>{d.ad}</td>
                                  <td>{d.tags}</td>
                                  <td>{d.clicks}</td>
                                  <td>{d.conversions}</td>
                                  <td>{d.LTV}</td>
                                  <td>{dayjs(d.date).format("DD-MM-YYYY")}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="separator separator-dashed border-gray-200" />
                        <Pagination
                          setLimit={setLimit}
                          limit={limit}
                          data={data}
                          activePage={activePage}
                          totalRecords={totalRecords}
                          onChange={(pageNumber) => {
                            setLoading(true);
                            setActivePage(pageNumber);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
